const formerPriest_data = {
    formerPriest_array: [
        {
            id:'1',
            formerPriest_duration:'(1988 - 1990)',
            formerPriest_Name:'REV. FR. GERORGE EDAKKALATHUR',
            img_path:'assets/img/former_priests/Rev.-Fr.-Gerorge-Edakkalathur1.jpg',
        },
        {
            id:'2',
            formerPriest_duration:'(1990 - 1994)',
            formerPriest_Name:'REV. FR. VARGHESE MANAVALAN',
            img_path:'assets/img/former_priests/Rev.-Fr.-Varghese-Manavalan1.jpg',
        },
        {
            id:'3',
            formerPriest_duration:'(1994 - 1998)',
            formerPriest_Name:'REV. FR. JOSEPH VATTAKUZHY',
            img_path:'assets/img/former_priests/Fr.Joseph Vattakuzhy.jpg',
        },
        {
            id:'4',
            formerPriest_duration:'(1998 - 2001)',
            formerPriest_Name:'REV. FR. SUNNY PERUMPUZHA',
            img_path:'assets/img/former_priests/Fr.-Sunny.jpg',
        },
        {
            id:'5',
            formerPriest_duration:'(2001 - 2004)',
            formerPriest_Name:'REV. FR. GEORGE CHOLLANAL',
            img_path:'assets/img/former_priests/Fr.George_Chollanal.jpg',
        },
        {
            id:'6',
            formerPriest_duration:'(2004 - 2007)',
            formerPriest_Name:'REV. FR. THOMAS THAIKKOOTTATHIL',
            img_path:'assets/img/former_priests/Fr.-Thomas.jpg',
        },
        {
            id:'7',
            formerPriest_duration:'(2007 - 2009)',
            formerPriest_Name:'REV. FR. SHAJI PARICKAPPALLIL',
            img_path:'assets/img/former_priests/Fr.-Shaji.jpg',
        },
        {
            id:'8',
            formerPriest_duration:'(2009 - 2012)',
            formerPriest_Name:'REV. FR. JOSE VAZHAKALAYIL',
            img_path:'assets/img/former_priests/Fr.-Jose.jpg',
        },
        {
            id: '9',
            formerPriest_duration: '(2012 - 2015)',
            formerPriest_Name: 'REV. FR. RAJESH MATHEW',
            img_path: 'assets/img/former_priests/rajesh_mathew.jpg',
        },
        {
            id: '10',
            formerPriest_duration: '(2015 - 2018)',
            formerPriest_Name: 'REV. FR. JACOB PORATHUR',
            img_path: 'assets/img/former_priests/fr.jacob.jpg',
        },
        {
            id: '11',
            formerPriest_duration: '(2018 - 2021)',
            formerPriest_Name: 'REV. FR. KURIAKOSE KALAMPARAMBATH',
            img_path: 'assets/img/former_priests/fr.kuriakose_kalamparambath.jpg',
        },
    ]
};

export default formerPriest_data;