import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const prayergroupTeam = {
    team_array: [
        {
            id: 1,
            div_id: 'collapseOne2',
            panel_title: 'Prayer Group Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car1-thumb.png'),
                    // img: imagePath('demo/car1.png'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Sr. Molly',
                    post_name: 'Animator',
                    photo_path: imagePath('team/sr_molly.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Jose A D',
                    post_name: 'Prayer Group Leader',
                    photo_path: imagePath('team/jose_a_d.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Molly Anthony',
                    post_name: 'Core Member',
                    // photo_path: imagePath('team/jose_a_d.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'K.V. Yesudas',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/yesudas_k_v.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'P. J. Joseph',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/joseph_p_j.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'P.J. Francis',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/francis_p_j.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Celine Mathew',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/celine_mathew.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Baby Wilson',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/baby_wilson.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Mathew Francis',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/mathew_francis_prayer.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Thomas C K',
                    post_name: 'Core Member',
                    photo_path: imagePath('team/thomas_c_k.jpg'),
                    social_networking: 'hidden',
                },
                // {
                //     name: 'Bijoy Joesph',
                //     post_name: 'Assistant Head Master',
                //     photo_path: imagePath('team/bijoy_joseph.jpeg'),
                //     social_networking: 'hidden',
                // },
                // {
                //     name: 'Maria Francis',
                //     post_name: 'Secretary',
                //     photo_path: imagePath('team/maria_francis.jpg'),
                //     social_networking: 'hidden',
                // },
            ],
        },
    ]
};

export default prayergroupTeam;
