import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const altarBoysTeam = {
    team_array: [
        {
            id: 1,
            div_id: 'collapseOne2',
            panel_title: 'Altar Boys Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car1-thumb.png'),
                    // img: imagePath('demo/car1.png'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Mathew Francis',
                    post_name: 'Leader',
                    photo_path: imagePath('team/mathew_francis.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Jobin Abraham ',
                    post_name: 'Assistant Leader',
                    photo_path: imagePath('team/jobin_abraham.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Melvin Antony',
                    post_name: 'Treasurer',
                    photo_path: imagePath('team/melvin_antony.jpg'),
                    social_networking: 'hidden',
                },
            ],
        },
    ]
};

export default altarBoysTeam;