import React from 'react';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import _ from 'lodash';
import { getHomeTilesData } from '../data/homeTiles';
import { carousel } from '../data/carousel';
import API from '../apiRoutes';
import { Carousel, HomeTiles, SmallCarousel, AssetHelper } from '../components';
import BaseView from './BaseView';
// import events from '../data/events';
import { Link } from 'react-router-dom';

const { BASE_URI, ROUTES } = API;
const { getContentFrom } = AssetHelper;
dayjs.extend(relativeTime);

class Home extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modalOpen: false,
      eventObj: {},
      cardsArr: []
    };
  }

  componentWillMount() {
    document.title = `${ this.state.DEFAULT_TITLE } :: Home`;
    this.loadEventsPopup();

    getHomeTilesData().then(({ cardsArr }) => this.setState({ cardsArr }));
    getContentFrom(`${ BASE_URI }${ ROUTES.OBITUARIES.GET }`)
      .then((data) => this.setState({ obituariesArr: data }));
  }

  loadHomeTiles() {
    const { cardsArr } = this.state;
    if (cardsArr && cardsArr.length > 0) {
      return <HomeTiles cards={ cardsArr } />;
    }
    return false;
  }

  loadObituaries() {
    const { obituariesArr } = this.state;
    if (obituariesArr && obituariesArr.length > 0) {
      return <SmallCarousel data={ obituariesArr } />;
    }
  }
  
  loadEventsPopup() {
    // const eventArr = _.filter(events, (event) => {
    //   const daysLeft = dayjs(event.eventDate).fromNow(true);
    //   event.daysLeftFromNow = daysLeft;
    //   return event.showEventPopupOnLoad && Number(daysLeft.split(' ')[0]) >= 0;
    // });

    // this.setState({ eventObj: events[0] });
  }

  renderPage() {
    const { eventPosterURL, eventPosterAltText, popupModalStyle } = this.state.eventObj;
    return (
      <React.Fragment>
        <Carousel carouselData={ carousel.carouselData } />
        <Modal
          centered
          className="text-center"
          bodyStyle={ { padding: 0 } }
          width="auto"
          keyboard={ true }
          footer={ null }
          destroyOnClose={ true }
          open={ this.state?.eventObj && this.state.modalOpen }
          onCancel={ () => this.setState({ modalOpen: false }) }
          >
          <Link to="/parish_life/upcoming_events">
            <img src={ eventPosterURL } alt={ eventPosterAltText } style={ popupModalStyle } />
          </Link>
        </Modal>
        <div className="main">
          <div className="container">
            <section className="ms-component-section">
              {this.loadHomeTiles()}
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Home;
