import { AssetHelper } from '../components';
const { imagePath } = AssetHelper;

const galleryCards = [
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/W3GwoqN7ZfkPK54r9',
        coverImage: imagePath('gallery/pithrudinam_2023.jpg'),
        title: 'Pithrudinam',
        content: 'Pithrudinam',
        year: '2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/Xsx2kS2LwqnAMN9n6',
        coverImage: imagePath('gallery/palm_sunday_2023.jpg'),
        title: 'Palm Sunday',
        content: 'Palm Sunday',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/smg8KbVhNb8gY1ua7',
        coverImage: imagePath('gallery/maundy_thursday_2023.jpg'),
        title: 'Maundy Thursday',
        content: 'Maundy Thursday',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/Q7y61ezCUygVYybU6',
        coverImage: imagePath('gallery/good_friday_2023.jpg'),
        title: 'Good Friday',
        content: 'Good Friday',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/BoTFYqtemqACVvBK9',
        coverImage: imagePath('gallery/easter_2023.jpg'),
        title: 'Easter',
        content: 'Easter',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/ksynUWpcccWGtjvF8',
        coverImage: imagePath('gallery/christmas_2023.jpg'),
        title: 'Christmas',
        content: 'Christmas',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/ksynUWpcccWGtjvF8',
        coverImage: imagePath('gallery/keralotsavam_2023.jpg'),
        title: 'Keralotsavam',
        content: 'Keralotsavam',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/xiEySFyXqSYffgbw6',
        coverImage: imagePath('gallery/st_thomas_feast_2023.jpg'),
        title: 'St. Thomas Feast',
        content: 'St. Thomas Feast',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/XxXQAvU7B9WXwTkKA',
        coverImage: imagePath('gallery/sunny_choir_2023.jpg'),
        title: 'Fr. Sunny Memorial Choir Competition',
        content: 'Fr. Sunny Memorial Choir Competition',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/XxXQAvU7B9WXwTkKA',
        coverImage: imagePath('gallery/fr_shibu_jubilee_celebration.jpg'),
        title: 'Fr. Shibu Jubilee Celebration',
        content: 'Fr. Shibu Jubilee Celebration',
        year:'2023'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/n5MdiihsuRfHw7fF9',
        coverImage: imagePath('gallery/parish_day_2024.jpg'),
        title: 'Parish Day',
        content: 'Parish Day',
        year:'2024'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/zUnmBa8RHH8CNk3VA',
        coverImage: imagePath('gallery/palm-sunday-2024.jpg'),
        title: 'Parish Day',
        content: 'Parish Day',
        year:'2024'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/JLr95muXtwM2TMiGA',
        coverImage: imagePath('gallery/maundy-thursday-2024.jpg'),
        title: 'Maundy Thursday',
        content: 'Maundy Thursday',
        year:'2024'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/nfWuhbvao5Z9RUcf8',
        coverImage: imagePath('gallery/good-friday-2024.jpg'),
        title: 'Good Friday',
        content: 'Good Friday',
        year:'2024'
    },
    {
        googlePhotosUrl: 'https://photos.app.goo.gl/7QxdMMKnjWbA9t5F9',
        coverImage: imagePath('gallery/easter-2024.jpg'),
        title: 'Easter',
        content: 'Easter',
        year:'2024'
    }
];

export default galleryCards ;