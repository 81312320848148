import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer, NavBar } from '../components';
import BaseView from './BaseView';

class Page_404 extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
    document.title = `${ this.state.DEFAULT_TITLE } :: 404`;
  }

  render() {
    return (
      <div className="404">
        <Header />
        <NavBar />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-md-offset-2">
              <div className="card animated fadeInUp animation-delay-7 color-primary withripple">
                <div className="card-body-big color-dark">
                  <div className="text-center">
                    <h1 className="color-primary">Error 404</h1>
                    <h2>Page Not Found</h2>
                    <p className="lead lead-sm">We have not found what you are looking for.
                      <br />We have put our robots to search.</p>
                    <Link to="/" className="btn btn-primary btn-raised">
                      <i className="zmdi zmdi-home" /> Go Home
                    </Link>
                  </div>
                </div>
                <div className="ripple-container" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
};

export default Page_404;
