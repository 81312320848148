import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer, NavBar } from '../components';
import uuidv1 from 'uuid/v1';
import Team from '../components/Team';
import Catechism_data from '../data/catechism';
import Mathursangam_data from '../data/mathrusangam';
import Pithruvedi_data from '../data/pithruvedi';
import Youth_data from '../data/youth';
import AltarBoys_data from '../data/altarboys';
import Choir_data from '../data/choir';
import Food4Poor_data from '../data/food4poor';
import PrayerGroup_data from '../data/prayergroup';
import Media_data from '../data/media';
import BaseView from './BaseView';

class Associations extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
    document.title = document.title + ' :: Associations';
  }

  showAssociationsListPanel = false;

  showAssociationsPanel = false;

  tabClick(elemId) {
    this.removeActiveClass('li[class="nav-item"] > a[id*=tabLink_]');
    this.removeActiveClass('div[id*="tab_"]');
    // this.removeActiveClass('li[class="nav-item"] > a[id*=tabLink_]');
    elemId && this.addActiveClass(elemId);
  }

  removeActiveClass(selector) {
    const htmlNodeList = document.querySelectorAll(selector);
    for (const node of htmlNodeList) {
      node.classList.remove('active');
      node.classList.remove('show');
    }
  }

  addActiveClass(elemId) {
    const element = `#tab_${ elemId }`;
    console.log(document.querySelector(element), 'document.querySelector(element)');
    document.querySelector(element).classList.add('active');
    document.querySelector(element).classList.add('show');
    document.querySelector(`#tabLink_${ elemId }`).classList.add('active');
  }

  loadAssociationsTiles = () => {
    const { associationsList } = this.state;
    return associationsList && associationsList.map((node) =>
      <div className="col-md-4" key={ uuidv1() }>
        <div className="card">
          <a href={ node.associationPath }>
            <div className="card-body">
              <h3>{node.associationName}</h3>
            </div>
          </a>
        </div>
      </div>
    );
  };

  componentDidMount() {
    const associationsNodeList = [ ...document.querySelectorAll('a[id*=tabLink]') ];
    const associationsList = associationsNodeList.map((node) => ({ associationName: node.innerText, associationPath: node.hash }));
    this.setState({ associationsList });
    console.log(this.props.match.params.tab, 'props.match.params.tab');
    this.tabClick(this.props.match.params.tab);
  }

  componentWillUpdate(props, state) {
    if (props.match.params.tab !== this.props.match.params.tab) {
      this.tabClick(props.match.params.tab);
      console.log(props.match.params.tab, 'props.match.params.tab');
    }
    console.log(props, state, 'props, state');
  }

  render() {
    return (
      <div className="Associations">
        <Header />
        <NavBar />
        <br />
        <div className="container">
          <div className={ `panel panel-default mt-2 ${ this.props.match.params.tab ? 'd-none' : '' }` }>
            <div className="panel-body">
              <div className="card-group justify-content-center">
                {this.loadAssociationsTiles()}
              </div>
            </div>
          </div>
          <div class={ `card nav-tabs-ver-container ${ this.props.match.params.tab ? '' : 'd-none' }` }>
            <div class="row">
              <div class="col-lg-3">
                <ul class="nav nav-tabs-ver" role="tablist">
                  <li className="nav-item">
                    <Link to="/associations/catechism" id="tabLink_catechism" className="nav-link active" aria-controls="catechism">
                      <i className="zmdi zmdi-book" />
                      Catechism
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/mathrusangam" id="tabLink_mathrusangam" className="nav-link withoutripple" aria-controls="mathrusangam">
                      <i className="zmdi zmdi-female" />
                      Mathrusagam
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/pithruvedi" id="tabLink_pithruvedi" className="nav-link withoutripple" aria-controls="pithruvedi">
                      <i className="zmdi zmdi-male-alt" />
                      Pithruvedi
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/youth" id="tabLink_youth" className="nav-link withoutripple" aria-controls="youth">
                      <i className="zmdi zmdi-male-female" />
                      Youth
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/altarboys" id="tabLink_altarboys" className="nav-link withoutripple" aria-controls="altarboys">
                      <i className="zmdi zmdi-male-female" />
                      Altar Boys
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/choir" id="tabLink_choir" className="nav-link withoutripple" aria-controls="choir">
                      <i className="zmdi zmdi-male-female" />
                      Choir
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/prayergroup" id="tabLink_prayergroup" className="nav-link withoutripple" aria-controls="prayergroup">
                      <i className="zmdi zmdi-male-female" />
                      Prayer Group
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/food4poor" id="tabLink_food4poor" className="nav-link withoutripple" aria-controls="food4poor">
                      <i className="zmdi zmdi-male-female" />
                      Food for Poor
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/associations/media" id="tabLink_media" className="nav-link withoutripple" aria-controls="media">
                      <i className="zmdi zmdi-case-play" />
                      Media
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="col-lg-9 nav-tabs-ver-container-content">
                <div class="card-body">
                  <div class="tab-content">
                    <div className="tab-content">
                      <div role="tabpanel" className="tab-pane fade active show" id="tab_catechism">
                        <div className="ms-collapse animated fadeInUp" id="catechism" role="tablist" aria-multiselectable="true">
                          <Team data={ Catechism_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_mathrusangam">
                        <div className="ms-collapse animated fadeInUp" id="mathrusangam" role="tablist" aria-multiselectable="true">
                          <Team data={ Mathursangam_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_pithruvedi">
                        <div className="ms-collapse animated fadeInUp" id="pithruvedi" role="tablist" aria-multiselectable="true">
                          <Team data={ Pithruvedi_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_youth">
                        <div className="ms-collapse animated fadeInUp" id="youth" role="tablist" aria-multiselectable="true">
                          <Team data={ Youth_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_choir">
                        <div className="ms-collapse animated fadeInUp" id="choir" role="tablist" aria-multiselectable="true">
                          <Team data={ Choir_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_altarboys">
                        <div className="ms-collapse animated fadeInUp" id="altarboys" role="tablist" aria-multiselectable="true">
                          <Team data={ AltarBoys_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_prayergroup">
                        <div className="ms-collapse animated fadeInUp" id="prayergroup" role="tablist" aria-multiselectable="true">
                          <Team data={ PrayerGroup_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_food4poor">
                        <div className="ms-collapse animated fadeInUp" id="food4poor" role="tablist" aria-multiselectable="true">
                          <Team data={ Food4Poor_data } />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane fade" id="tab_media">
                        <div className="ms-collapse animated fadeInUp" id="media" role="tablist" aria-multiselectable="true">
                          <Team data={ Media_data } />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}

export default Associations;