import React from 'react';
import _ from 'lodash';
import TimelineCard from './TimelineCard';

const Timeline = (props) => {
  const { data } = props;
  const { event_array } = data;
  event_array.reverse();
  const timelineElems = _.map(event_array, (eventCards) =>
    <TimelineCard
      key={ eventCards.id }
      date_month={ eventCards.date_month }
      year={ eventCards.year }
      event_name={ eventCards.event_name }
      event_description_name={ eventCards.event_description_name }
      event_description={ eventCards.event_description }
    />
  );

  return (
    <ul className="ms-timeline-left">
      {timelineElems}
    </ul>
  );
};

export default Timeline;