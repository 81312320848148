import React from 'react';

const Collapsable = ({ div_id, display, href, panel_title, content, onClick, name }) => (
  <div className="mb-0 card card-primary">
    <div className="card-header" role="tab" id="headingOne2">
      <h4 className="card-title">
        <a
          name={name}
          className={display === "show" ? "withripple" : "collapsed withripple"}
          role="button"
          data-toggle="collapse"
          data-parent="#accordion2"
          href={href}
          aria-expanded={display === "show" ? true : false}
          aria-controls="collapseOne2"
          onClick={onClick}
        >
          <i className="zmdi zmdi-pin" /> {panel_title}
        </a>
      </h4>
    </div>
    <div
      id={div_id}
      className={`card-collapse collapse ${display}`}
      role="tabpanel"
      aria-labelledby="headingOne2"
    >
      <div className="row card-body wow d-flex justify-content-center">
        {content}
      </div>
    </div>
  </div>
);

  export default Collapsable;