export const navigation = {
    menuItems: [
        {
            id: '1',
            name: 'About Us',
            url: '/about/brief',
            isDropDown: true,
            subMenuItem: [
                {
                    name: 'In Brief',
                    url: '/about/brief',
                },
                {
                    name: 'History',
                    url: '/about/history'
                },
                {
                    name: 'Former Priests',
                    url: '/about/former_priests',
                },
                {
                    name: 'Our Teams',
                    url: '/about/team',
                    isDropDown: true,
                    isSubItem: true,
                    subMenuItem: [
                        {
                            name: 'Parish Priests',
                            url: '/about/team/parish_priests',
                        },
                        {
                            name: 'Trustees',
                            url: '/about/team/trustees',
                        },
                        {
                            name: 'Parish Council',
                            url: '/about/team/parish_council',
                        }
                    ]
                },
                {
                    name: 'Timings',
                    url: '/about/timings',
                }
            ]
        },
        {
            id: '2',
            name: 'Parish Life',
            url: '/parish_life/announcements',
            isDropDown: true,
            subMenuItem: [
                {
                    name: 'Annoucements',
                    url: '/parish_life/announcements',
                },
                {
                    name: 'Upcoming Events',
                    url: '/parish_life/upcoming_events',
                },
                // {
                //     name: 'Marriage Banns',
                //     url: '/parish_life/marriage_banns',
                // },
                // {
                //     name: 'Golden Jubilee',
                //     url: '/parish_life/golden_jubilee'
                // },
                // {
                //     name: 'Wedding Anniversary',
                //     url: '/parish_life/anniversary',
                // },
                // {
                //     name: 'Obituaries',
                //     url: '/parish_life/obituaries',
                // },
            ]
        },
        {
            id: '3',
            name: 'Associations',
            url: '/associations',
            isDropDown: true,
            subMenuItem: [
                {
                    name: 'Catechism',
                    url: '/associations/catechism'
                },
                {
                    name: 'Mathrusangam',
                    url: '/associations/mathrusangam'
                },
                {
                    name: 'Pithruvedi',
                    url: '/associations/pithruvedi'
                },
                {
                    name: 'Youth',
                    url: '/associations/youth'
                },
                {
                    name: 'Altar Boys',
                    url: '/associations/altarboys'
                },
                {
                    name: 'Choir',
                    url: '/associations/choir'
                },
                {
                    name: 'Prayer Group',
                    url: '/associations/prayergroup'
                },
                {
                    name: 'Food for Poor',
                    url: '/associations/food4poor'
                },
                {
                    name: 'Media',
                    url: '/associations/media'
                },
            ]
        },
        {
            id: '4',
            name: 'Prayers',
            url: '/prayers',
            isNavLink: true,
            isDropDown: false,
            isSubItem: false,
        },
        {
            id: '5',
            name: 'Get in Touch',
            url: '/get-in-touch/contact-us',
            isDropDown: true,
            subMenuItem: [
                {
                    name: 'Contact us',
                    url: '/get-in-touch/contact-us'
                },
                {
                    name: 'Prayer Request',
                    url: '/get-in-touch/prayer-request'
                },
            ]
        },
        {
            id: '6',
            name: 'Gallery',
            url: '/gallery',
            isNavLink: true,
            isDropDown: false,
            isSubItem: false,
        },
    ]
};
