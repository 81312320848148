import React from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

const SmallCarousel = (props) => {
const uniqueId = uuidv1();
  const getTextElem = props.data && props.data.map((node, index) => {
    return (
      <div className={ `carousel-item ${ index === 0 ? 'active' : '' }` } key={ uuidv1() }>
        <h3>{node.short_lines}</h3>
        <p dangerouslySetInnerHTML={ { __html: node.details.bodyHTML } }></p>
      </div>
    );
  });

  return (
    <div id={ `carousel-${ uniqueId }` } className="ms-carousel carousel slide" data-ride="carousel">
      {/* -- Indicators -- */}
      <ol className="carousel-indicators">
        <li data-target={ `#carousel-${ uniqueId }` } data-slide-to="0" className="active"></li>
        <li data-target={ `#carousel-${ uniqueId }` } data-slide-to="1"></li>
        <li data-target={ `#carousel-${ uniqueId }` } data-slide-to="2"></li>
      </ol>

      {/* -- Wrapper for slides -- */}
      <div className="carousel-inner" role="listbox">
        {getTextElem}
      </div>

      {/* -- Controls --*/}
      <a href={ `#carousel-${ uniqueId }` } className="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary left carousel-control-prev" role="button" data-slide="prev"><i className="zmdi zmdi-chevron-left"></i></a>
      <a href={ `#carousel-${ uniqueId }` } className="btn-circle btn-circle-xs btn-circle-raised btn-circle-primary right carousel-control-next" role="button" data-slide="next"><i className="zmdi zmdi-chevron-right"></i></a>
    </div>
  );
};

SmallCarousel.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SmallCarousel;
