import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { Header, Footer, NavBar } from '../components';

import galleryCards from '../data/gallery';
import { Link } from 'react-router-dom';
import Collapsable from '../components/Collapsable';

// Grouping gallery cards by year
const galleryCardsByYear = galleryCards.reduce((acc, card) => {
  const { year, ...rest } = card;
  if (!acc[year]) {
      acc[year] = [];
  }
  acc[year].push(rest);
  return acc;
}, {});

const GalleryCard = ({ coverImage, title, content, link }) => (
  <div className="col-lg-4 col-md-6 masonry-item wow fadeInUp animation-delay-2 ">
    <a href={ link } target="_blank" rel="noreferrer">
      <article className="card card-primary mb-4 wow materialUp animation-delay-5">
        <figure className="ms-thumbnail ms-thumbnail-left">
          <img className="img-fluid" alt="" src={ coverImage }/>
          <figcaption className="ms-thumbnail-caption text-center">
            <div className="ms-thumbnail-caption-content">
              <h3 className="ms-thumbnail-caption-title">{title}</h3>
              <p>Open in Google Photos</p>
            </div>
          </figcaption>
        </figure>
        <div className="card-header">
          <h3 style={ { color: '#03A9F4' } }>{content}</h3>
        </div>
      </article>
    </a>
  </div>
);

const removeActiveClass = (selector)  => {
  const htmlNodeList = document.querySelectorAll(selector);
  for (const node of htmlNodeList) {
    console.log(node, 'node1');
    console.log(node.classList, 'node');
    node?.classList.remove('show');
  }
};

const handleClick = (event) => {
  // removeActiveClass('div[id*="panel"]');
  console.log(event.target.name, 'event');
  const panelId = `#panel_${event.target.name}`;
  console.log(document.querySelector(panelId), panelId, 'document.querySelector(panelId)');
  const visible = document.querySelector(panelId).classList.contains('show') ? true : false;
  console.log(visible, visible ? 'hide': 'show', 'visible');
  document.querySelector(panelId).classList.remove(visible ? 'show': 'hide');
  document.querySelector(panelId).classList.add(visible ? 'hide': 'show');
};

// const GalleryCardsElemsByYear = Object.keys(galleryCardsByYear).map(year => (
//   <Collapsable
//     key={uuid()}
//     name={year}
//     display={'hide'}
//     panel_title={year}
//     div_id={`panel_${year}`}
//     href={'/gallery'}
//     onClick={handleClick}
//     content={_.map(
//       galleryCardsByYear[year],
//       ({ coverImage, title, content = "", googlePhotosUrl }) => (
//         <GalleryCard
//           key={uuid()}
//           coverImage={coverImage}
//           title={title}
//           content={content}
//           link={googlePhotosUrl}
//         />
//       )
//     )}
//   />
// ));

const GalleryCardsElemsByYear = Object.keys(galleryCardsByYear).map(year => (
  <div className="mb-0 card card-primary" key={uuid()}>
    <div className="card-header" role="tab" id={`heading${year}`}>
      <h4 className="card-title">
        <a className="collapsed withripple" role="button" data-bs-toggle="collapse" name={`${year}`} data-bs-parent="#accordion2" aria-expanded="false" aria-controls={`year${year}`} onClick={handleClick}>
          <i className="zmdi zmdi-pin" /> {year}
        </a>
      </h4>
    </div>
    <div id={`panel_${year}`} className="card-collapse collapse show" role="tabpanel" aria-labelledby={`heading${year}`}>
      <div className="card-body">
        <div className="row masonry-container" style={{ height: 'fit-content !important' }}>
          {_.map(
            galleryCardsByYear[year],
            ({ coverImage, title, content = "", googlePhotosUrl }) => (
              <GalleryCard
                key={_.uniqueId()}
                coverImage={coverImage}
                title={title}
                content={content}
                link={googlePhotosUrl}
              />
            )
          )}
        </div>
      </div>
    </div>
  </div>
));

const Gallery = () => {
  document.title = document.title + ' :: 404';
  return (
    <div className="gallery">
      <Header />
      <NavBar />
      <div className="container mt-3">
        {/* <div className="row masonry-container"> */}
        {/* <GalleryCards list={ galleryCards }/> */}
        <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
          {GalleryCardsElemsByYear}
        </div>
      </div>
      <Footer />
    </div>
  );
};

GalleryCard.propTypes = {
    coverImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    link: PropTypes.string.isRequired
};

export default Gallery;