import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const team = {
    team_array: [
        {
            id: '1',
            div_id: 'parish_priests',
            panel_title: 'Parish Priests',
            href: '#parish_priests',
            display: 'hide',
            teams: [
                {
                    name: 'Rev. Fr. Shibu Pulickal',
                    post_name: 'Vicar',
                    photo_path: imagePath('team/fr.shibu_pulickal.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 4
                },
                {
                    name: 'Rev. Fr. Ashly Vadakeveetil',
                    post_name: 'Assistant Vicar',
                    photo_path: imagePath('team/fr.ashly_vadakeveetil.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 4
                }
            ],
        },
        {
            id: '2',
            div_id: 'trustees',
            panel_title: 'Trustees',
            href: '#trustees',
            display: 'hide',
            teams: [
                {
                    name: 'Antony Joseph',
                    post_name: 'First Trustee',
                    photo_path: imagePath('team/antony_joseph.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joby Antony',
                    post_name: 'Second Trustee',
                    photo_path: imagePath('team/joby_antony.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Felix Wilson',
                    post_name: 'Third Trustee',
                    photo_path: imagePath('team/felix_wilson.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                }
            ]
        },
        {
            id: '3',
            div_id: 'parish_council',
            panel_title: 'Parish Council Members',
            href: '#parish_council',
            display: 'hide',
            teams: [
                {
                    name: 'Sr. Molly',
                    post_name: 'By Office',
                    post_subdetails: 'Superior DSHJ Convent',
                    photo_path: imagePath('team/sr_molly.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joy Paul',
                    post_name: 'Pastor Council Rep',
                    post_subdetails: 'Pastor Council',
                    photo_path: imagePath('team/joy_paul.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Laisa Baby',
                    post_name: 'Pastor Council Rep',
                    photo_path: imagePath('team/laisa_baby.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Rohit Albin',
                    post_name: 'Pastor Council Rep',
                    photo_path: imagePath('team/rohit_albin.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Rajan Mathew',
                    post_name: 'Forane Council Rep',
                    photo_path: imagePath('team/rajan_mathew.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Leo Thomson',
                    post_name: 'Forane Council Rep',
                    photo_path: imagePath('team/leo_chirayath.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joji Chacko',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Alphonsa Unit, Shahad',
                    photo_path: imagePath('team/joji_chacko.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Francis P F',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Alphonsa Unit, Shahad',
                    photo_path: imagePath('team/francis_p_f.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joseph Anthony',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Andrew\'s Unit, Chickenghar',
                    photo_path: imagePath('team/joseph_anthony.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Biju Lukose',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Antony\'s Unit, Holy Cross',
                    photo_path: imagePath('team/biju_lukose.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Celine Mathew',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Chavara Kuriakose Unit, Barave',
                    photo_path: imagePath('team/celine_mathew.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Lijini Nikhil',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Chavara Kuriakose Unit, Barave',
                    photo_path: imagePath('team/lijini_nikhil.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Mathew K V',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Chavara Kuriakose Unit, Godrej Hill',
                    photo_path: imagePath('team/mathew_k_v.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Sujo Joseph',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Chavara Kuriakose Unit, Godrej Hill',
                    photo_path: imagePath('team/sujo_joseph.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Liju Rappai',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Dominic Savio Unit, Khadakpada',
                    photo_path: imagePath('team/liju_rappai.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Vincent Aloor',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Don Bosco Unit, Wayale Nagar',
                    photo_path: imagePath('team/vincent_aloor.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Maria Tony',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Don Bosco Unit, Wayale Nagar',
                    photo_path: imagePath('team/maria_tony.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Lincen Louis',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. James Unit, Masoba Maidan',
                    photo_path: imagePath('team/lincen_louis.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Roy Mathews',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. John Unit, SMCA',
                    photo_path: imagePath('team/roy_mathews.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Johny Mathew',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Joseph Unit, Radha Nagar',
                    photo_path: imagePath('team/johny_mathew.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'James Abraham',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Jude Unit, Rambaug',
                    photo_path: imagePath('team/james_abraham.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Martin V J',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Jude Unit, Rambaug',
                    photo_path: imagePath('team/martin_v_j.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joy K P',
                    post_name: 'Unit Rep',
                    post_subdetails: 'Little Flower Unit, Adharwadi',
                    photo_path: imagePath('team/joy_k_p.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Anthony Joseph',
                    post_name: 'Unit Rep',
                    post_subdetails: 'Little Flower Unit, Adharwadi',
                    photo_path: imagePath('team/antony_joseph.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Lissy Davis',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Mother Teresa Unit, Syndicate',
                    photo_path: imagePath('team/lissy_davis.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'George Sebastian',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Paul Unit, Birla College',
                    photo_path: imagePath('team/george_sebastian.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Sojan P G',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Paul Unit, Birla College',
                    photo_path: imagePath('team/sojan_p_g.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Shreedas Joseph',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Peter\'s Unit, Church Area',
                    photo_path: imagePath('team/shreedas_joseph.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Anthony Varghese',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Peter\'s Unit, Church Area',
                    photo_path: imagePath('team/anthony_varghese.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Jojo Nicholas',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Sebastian Unit, Station Ward',
                    photo_path: imagePath('team/jojo_nicholas.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Danny John',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Thomas Unit, Karnik Road',
                    photo_path: imagePath('team/danny_john.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Thomas K V',
                    post_name: 'Unit Rep',
                    post_subdetails: 'St. Thomas Unit, Karnik Road',
                    photo_path: imagePath('team/thomas_k_v.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joy Jacob',
                    post_name: 'Unit Rep',
                    post_subdetails: 'Vijayamatha Unit, Ulhasnagar',
                    photo_path: imagePath('team/joy_jacob.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Ajay Thomas',
                    post_name: 'By Office',
                    post_subdetails: 'Pithruvedi President',
                    photo_path: imagePath('team/ajay_thomas.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Maria Tony',
                    post_name: 'By Office',
                    post_subdetails: 'Mathrusangam President',
                    photo_path: imagePath('team/maria_tony.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Felix Wilson',
                    post_name: 'By Office',
                    post_subdetails: 'Youth President',
                    photo_path: imagePath('team/felix_wilson.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Jose A D',
                    post_name: 'By Office',
                    post_subdetails: 'Prayer Group Leader',
                    photo_path: imagePath('team/jose_a_d.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joby Antony',
                    post_name: 'By Office',
                    post_subdetails: 'Choir Master & Youth Animator',
                    photo_path: imagePath('team/joby_antony.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Bijo Wilson',
                    post_name: 'By Office',
                    post_subdetails: 'Cathechism Headmaster',
                    photo_path: imagePath('team/bijo_wilson.jpeg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Ruby Thomas',
                    post_name: 'By Office',
                    post_subdetails: 'Mathrusangam Forane President',
                    photo_path: imagePath('team/ruby_thomas.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Angitha Mathew',
                    post_name: 'By Office',
                    post_subdetails: 'DEXCO',
                    photo_path: imagePath('team/angitha_mathew.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Xavier P D',
                    post_name: 'By Office',
                    post_subdetails: 'Kudumba Koottayamma President',
                    photo_path: imagePath('team/xavier_p_d.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Joseph K Mathoor',
                    post_name: 'Ex-Officio Member',
                    post_subdetails: 'Ex-Forane Secretary',
                    photo_path: imagePath('team/joseph_k_mathoor.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'N D Mathew',
                    post_name: 'Ex-Officio Member',
                    post_subdetails: 'Ex-First Trustee',
                    photo_path: imagePath('team/n_d_mathew.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Bijoy Chacko',
                    post_name: 'Ex-Officio Member',
                    post_subdetails: 'Ex-Third Trustee',
                    photo_path: imagePath('team/bijoy_chacko.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                
                {
                    name: 'Thomas Vazhapilly',
                    post_name: 'Nominated Member',
                    photo_path: imagePath('team/thomas_vazhapilly.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Santha Phillip',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Women Representative',
                    photo_path: imagePath('team/santha_phillip.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Elizabeth Chacko',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Women Representative',
                    photo_path: imagePath('team/elizabeth_chacko.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Gabby Yogesh',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Women Representative',
                    photo_path: imagePath('team/gabby_yogesh.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Divya Job',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Youth Representative',
                    photo_path: imagePath('team/divya_job.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Simon Nicholas',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Working Youth Representative',
                    photo_path: imagePath('team/simon_nicholas.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Rochelle Varghese',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Working Youth Representative',
                    photo_path: imagePath('team/rochelle_varghese.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
                {
                    name: 'Anish Thottungal',
                    post_name: 'Nominated Member',
                    post_subdetails: 'Media & Young Couples Rep',
                    photo_path: imagePath('team/anish_joy.jpg'),
                    social_networking: 'hidden',
                    cardWidth: 3
                },
            ]
        }
    ]
};

export default team;