import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const mathrusangam = {
    team_array:[
        {
            id:1,
            div_id: 'collapseOne2',
            panel_title: 'Mathrusangam Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car2-thumb.png'),
                    // img: imagePath('demo/car2.jpg'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Sr. Molly',
                    post_name: 'Animator',
                    photo_path: imagePath('team/sr_molly.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Maria Tony',
                    post_name: 'President',
                    photo_path: imagePath('team/maria_tony.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Teetha Martin',
                    post_name: 'Vice President',
                    photo_path: imagePath('team/teetha_martin.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Dani Antony',
                    post_name: 'Secretary',
                    photo_path: imagePath('team/dani_antony.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Annie Ajay',
                    post_name: 'Joint Secretary',
                    photo_path: imagePath('team/annie_ajay.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Sheeba James',
                    post_name: 'Treasurer',
                    photo_path: imagePath('team/sheeba_james.jpg'),
                    social_networking: 'hidden',
                },
            ],
        },
    ]
};

export default mathrusangam;