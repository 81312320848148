import React, { Component, Fragment } from 'react';
import NavBrand from './NavBrand';
import NavMenu from './NavMenu';
import { navigation } from '../data/navigation';
import SideNavBar from '../components/SideNavBar';

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      menuStatus: 'close'
    };
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick() {
    switch (this.state.menuStatus) {
      default:
        this.setState({
          menuStatus: 'close',
        });
        break;
      case 'close':
        this.setState({
          menuStatus: 'open',
        });
        break;
    }
  }
  
  componentDidMount() {
    const n = document.querySelector('.ms-navbar');
    const body = document.body;
    const i = document.querySelector('.ms-site-container').classList.contains('ms-nav-fixed');
    window.addEventListener('scroll', function (e) {
      if (i || 120 < window.scrollY) {
        n.className = 'navbar navbar-expand-md navbar-static ms-navbar ms-nav-fixed ms-navbar-primary shrink fixed-top';
        body.className = 'bd-scroll';
      }

      if (i || window.scrollY < 121) {
        // console.log(n.className);
        n.className = 'navbar navbar-expand-md navbar-static ms-navbar ms-nav-fixed ms-navbar-primary';
        body.className = '';
      }
    });
  }

  render() {
    const menuItems = navigation.menuItems;
    return (
      <Fragment>
        <nav className="navbar navbar-expand-md  navbar-static ms-navbar ms-navbar-primary">
          <div className="container container-full">
            <NavBrand />
            <NavMenu menuItems={ menuItems } />
            <button onClick={ this.handleClick } className="btn-navbar-menu" style={ { backgroundColor: '#03a9f4' } }>
              <i className="zmdi zmdi-menu" />
              {console.log(this.state.menuStatus)}
            </button>
          </div>
        </nav>
        <SideNavBar
          menuStatus = { this.state.menuStatus }
        />
      </Fragment>
    );
  }
}

export default NavBar;
