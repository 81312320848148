import React, { Fragment } from 'react';
// import Sitemap from './Sitemap';
// import LastArticle from './LastArticle';
// import { siteMap } from '../data/siteMap';
// import { lastArticleArr } from '../data/lastArticle';

const Footer = (props) => {
  return (
    <Fragment>
      {/* <aside className="ms-footbar">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ms-footer-col">
              <Sitemap links={ siteMap.linksArr }/>
              <div className="ms-footbar-block">
                <h3 className="ms-footbar-title">Subscribe</h3>
                <p>Lorem ipsum Amet fugiat elit nisi anim mollit minim labore ut esse Duis ullamco ad dolor veniam velit.</p>
                <form>
                  <div className="form-group label-floating mt-2 mb-1">
                    <div className="input-group ms-input-subscribe">
                      <label className="control-label" htmlFor="ms-subscribe">
                        <i className="zmdi zmdi-email" /> Email Adress</label>
                      <input type="email" id="ms-subscribe" className="form-control" /> </div>
                  </div>
                  <button className="ms-subscribre-btn" type="button">Subscribe</button>
                </form>
              </div>
            </div>
            <div className="col-lg-5 col-md-7 ms-footer-col ms-footer-alt-color">     
              {/* <LastArticle articles={ lastArticleArr.articlesArr } />
              <div className="ms-footbar-block">
                <h3 className="ms-footbar-title text-center mb-2">Daily Programs</h3>
                <div class="mass-timings">
                  <h4>Mass Timings</h4>
                  <h5>Sunday</h5> <span>7:00 am</span><br/>
                  <h5>Sunday</h5> <span>7:00 am</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 ms-footer-col ms-footer-text-right">
              <div className="ms-footbar-block">
                <div className="ms-footbar-title">
                  <span className="ms-logo ms-logo-white ms-logo-sm mr-1">M</span>
                  <h3 className="no-m ms-site-title">Material
                    <span>Style</span>
                  </h3>
                </div>
                <address className="no-mb">
                  <p>
                    <i className="color-danger-light zmdi zmdi-pin mr-1" /> 795 Folsom Ave, Suite 600</p>
                  <p>
                    <i className="color-warning-light zmdi zmdi-map mr-1" /> San Francisco, CA 94107</p>
                  <p>
                    <i className="color-info-light zmdi zmdi-email mr-1" />
                    <Link to="mailto:joe@example.com">example@domain.com</Link>
                  </p>
                  <p>
                    <i className="color-royal-light zmdi zmdi-phone mr-1" />+34 123 456 7890 </p>
                  <p>
                    <i className="color-success-light fa fa-fax mr-1" />+34 123 456 7890 </p>
                </address>
              </div>
              <div className="ms-footbar-block">
                <h3 className="ms-footbar-title">Social Media</h3>
                <div className="ms-footbar-social">
                  <Link to="" className="btn-circle btn-facebook">
                    <i className="zmdi zmdi-facebook" />
                  </Link>
                  <Link to="" className="btn-circle btn-twitter">
                    <i className="zmdi zmdi-twitter" />
                  </Link>
                  <Link to="" className="btn-circle btn-youtube">
                    <i className="zmdi zmdi-youtube-play" />
                  </Link>
                  <br />
                  <Link to="" className="btn-circle btn-google">
                    <i className="zmdi zmdi-google" />
                  </Link>
                  <Link to="" className="btn-circle btn-instagram">
                    <i className="zmdi zmdi-instagram" />
                  </Link>
                  <Link to="" className="btn-circle btn-github">
                    <i className="zmdi zmdi-github" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside> */}
      <footer className="ms-footer">
        <div className="container">
          <p>Cathedral Media &#169; {(new Date().getFullYear())}</p>
        </div>
      </footer>
      <div className="btn-back-top">
        <button onClick={ () => window.scrollTo(0, 0) } data-scroll id="back-top" className="btn-circle btn-circle-primary btn-circle-sm btn-circle-raised ">
          <i className="zmdi zmdi-long-arrow-up" />
        </button>
      </div>
    </Fragment>
  );
};

export default Footer;
