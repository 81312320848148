import React from 'react';
import { Link } from 'react-router-dom';

const TeamCard = (props) => {
  return (
    <div className={ `col-lg-${ props.cardWidth } col-md${ props.cardWidth } col-sm-4` }>
      <div className="card card-warning wow zoomInUp mb-4 animation-delay-2" style={ { visibility: 'visible', animationName: 'zoomInUp' } }>
        <div className="withripple zoom-img">
          <Link to="">
            <img src={ props.photo_path } alt="..." className="img-fluid" />
          </Link>
          <div className="ripple-container" /></div>
        <div className="card-body">
          <span className="badge badge-warning pull-right">{props.post_name}</span>
          <h3 className="color-warning mb-0">{props.name} {props.id}</h3>
          <small>{props.post_subdetails}</small>
          {/* <p>Lorem ipsum dolor sit amet, consectetur alter adipisicing elit. Facilis, natuse inse voluptates officia repudiandae beatae magni es magnam autem molestias.</p> */}
          <div className="row" style={ { display: props.social_networking === 'hidden' ? 'none' : 'block' } }>
            <div className="col">
              <Link to="" className="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-facebook">
                <i className="zmdi zmdi-facebook" />
              </Link>
              <Link to="" className="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-twitter">
                <i className="zmdi zmdi-twitter" />
              </Link>
              <Link to="" className="btn-circle btn-circle-raised btn-circle-xs mt-1 mr-1 no-mr-md btn-instagram">
                <i className="zmdi zmdi-instagram" />
              </Link>
            </div>
            <div className="col text-right">
              <Link to="" className="btn btn-raised btn-sm btn-warning">
                <i className="zmdi zmdi-account" /> Profile</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;