import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Footer, NavBar } from '../components';
import BaseView from './BaseView';
import Timeline from '../components/Timeline';
import timeline_data from '../data/timeline';
import FormerPriest from '../components/FormerPriest';
import FormerPriest_data from '../data/formerPriest';
import Team from '../components/Team';
import TeamPanel_data from '../data/team';
//const { imagePath } = AssetHelper;

class About extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
    document.title = `${ this.state.DEFAULT_TITLE } :: About`;
  }

  tabClick(elemId) {
    this.removeActiveClass("li[className='nav-item'] > a[id*=tabLink_]");
    this.removeActiveClass("div[id*='tab_']");
    // this.removeActiveClass("li[className="nav-item"] > a[id*=tabLink_]");
    this.addActiveClass(elemId);
  }

  removeActiveClass(selector) {
    const htmlNodeList = document.querySelectorAll(selector);
    for (const node of htmlNodeList) {
      node.classList.remove('active');
      node.classList.remove('show');
    }
  }

  addActiveClass(elemId) {
    const element = `#tab_${ elemId }`;
    document.querySelector(element).classList.add('active');
    document.querySelector(element).classList.add('show');
    document.querySelector(`#tabLink_${ elemId }`).classList.add('active');
  }

  componentDidMount() {
    this.tabClick(this.props.match.params.tab);
  }

  componentWillUpdate(props, state) {
    if (props.match.params.tab !== this.props.match.params.tab) {
      this.tabClick(props.match.params.tab);
    }
  }

  render() {
    return (
      <div className="about">
        <Header />
        <NavBar />
        <br />
        <div className="container">
          <div className="card">
            {/* Nav tabs */}
            <ul className="nav nav-tabs nav-tabs-full nav-tabs-5 shadow-2dp shadow-2dp" role="tablist">
              <li className="nav-item"><Link id="tabLink_brief" className="nav-link withoutripple" to="/about/brief" aria-controls="brief"><i className="zmdi zmdi-email" /><span className="d-none d-sm-inline">In Brief</span></Link></li>
              <li className="nav-item"><Link id="tabLink_history" className="nav-link withoutripple" to="/about/history" aria-controls="history"><i className="zmdi zmdi-time-restore-setting" /> <span className="d-none d-sm-inline">History</span></Link></li>
              <li className="nav-item"><Link id="tabLink_former_priests" className="nav-link withoutripple" to="/about/former_priests" aria-controls="former_priest"><i className="zmdi zmdi-account" /> <span className="d-none d-sm-inline">Former Priests</span></Link></li>
              <li className="nav-item"><Link id="tabLink_team" className="nav-link withoutripple" to="/about/team" aria-controls="team"><i className="zmdi zmdi-home" /> <span className="d-none d-sm-inline">Our Team</span></Link></li>
              <li className="nav-item"><Link id="tabLink_timings" className="nav-link withoutripple" to="/about/timings" aria-controls="team"><i className="zmdi zmdi-time" /> <span className="d-none d-sm-inline">Timings</span></Link></li>
            </ul>
            <div className="card-body">
              {/* Tab panes */}
              <div className="tab-content">
                {/* In Brief Tab */}
                <div role="tabpanel" className="tab-pane fade active show" id="tab_brief">
                  <div className="card-body card-body-big animated fadeInUp">
                    {/* <h2 className="text-center fw-500">About Us</h2>
                    <p className="lead text-center center-block mw-800">Discover our projects and the rigorous process of creation. Our principles are creativity, design, experience and knowledge.</p> */}
                    <div className="row mt-4">
                      <div className="text-justify">
                        <h3 className="color-primary">Know us</h3>
                        <p className="dropcaps">Syro-Malabar Church is founded by St. Thomas, one of the twelve Apostles of Jesus Christ. St. Thomas came to India in 52 AD and established seven and a half churches in various parts of our country. He became a martyr in 72 AD at Mylapore in Chennai.</p>
                        <p>Syro-Malabar Church is the second largest among the Oriental Churches. Their faith is Christianity in communion with the Universal Church headed by the Pope, but the liturgy is of East Syrian Church origin and their culture, traditions and practices purely Indian.</p>
                        <p>From 1960 onwards Hoy Mass in Malayalam was offered by priests of OFM and CMI Congregation at our Lady of Lourdes Church, arranged by KCA. In 1980, the Pastoral care was entrusted to CMI Fathers. On 14th May 1987, Msgr. Paul Chittilappilly along with Rev.Fr. Jacob Porathur, George Vadakkethala and George Edakkalathur were sent to Mumbai by SMBC. Fr. George Edakkalathur was entrusted the central zone, including Kalyan.</p>
                        <p>On 30th April 1988, the Diocese of Kalyan was created by the Holy Father, Pope John Paul II and Msgr. Paul Chittilappilly was ordained the First Bishop of Kalyan Diocese on 24th April 1988.</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* History Tab */}
                <div role="tabpanel" className="tab-pane fade" id="tab_history">
                  <div className="card-body card-body-big wow animated fadeInUp" style={ { visibility: 'visible', animationName: 'fadeInUp' } }>
                    <h3 className="text-center fw-500 mb-6">Timeline</h3>
                    <Timeline data={ timeline_data } />
                  </div>
                </div>
                {/* Former Priest Tab */}
                <div role="tabpanel" className="tab-pane wow animated fadeInUp" id="tab_former_priests">
                  <FormerPriest data={ FormerPriest_data } />
                </div>
                {/* Our Team Tab */}
                <div role="tabpanel" className="tab-pane fade" id="tab_team">
                  <div className="ms-collapse wow animated fadeInUp " id="accordion2" role="tablist" aria-multiselectable="true">
                    <Team data={ TeamPanel_data } selectedSection={ this.props.match.params.section }/>
                  </div>
                </div>
                {/* Services Timings Tab */}
                <div role="tabpanel" className="tab-pane fade" id="tab_timings">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card card-primary">
                        <div className="card-header">
                          <h3 className="card-title">Mass Timings</h3>
                        </div>
                        <div className="card-body">
                          <p className="font-weight-bold">Monday - Saturday:
                            <br /><span className="ml-30 font-weight-normal">6:30 am (Malayalam)</span>
                            <br /><span className="ml-30 font-weight-normal">6:30 pm (Malayalam)</span>
                          </p>
                          <p className="font-weight-bold">Sunday:
                            <br /><span className="ml-30 font-weight-normal">7:30 am (Malayalam)</span>
                            <br /><span className="ml-30 font-weight-normal">9:15 am (English)</span>
                            <br /><span className="ml-30 font-weight-normal">10:30 am (Marathi)</span>
                            <br /><span className="ml-30 font-weight-normal">6:00 pm (Malayalam)</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card card-primary">
                        <div className="card-header">
                          <h3 className="card-title">Novena Timings</h3>
                        </div>
                        <div className="card-body">
                          <p className="font-weight-bold">Wednesday:
                            <br /><span className="font-weight-normal">After 6:30 pm Mass (Malayalam)(Perpetual Succour)</span>
                          </p>
                          <p className="font-weight-bold">Saturday:
                            <br /><span className="font-weight-normal">After 6:30 pm Mass (Malayalam)(St. Thomas)</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card card-primary">
                        <div className="card-header">
                          <h3 className="card-title">Parish Office Timings</h3>
                        </div>
                        <div className="card-body">
                          <p className="font-weight-bold">Wednesday:
                            <br /><span className="font-weight-normal">After 6:30 am Mass </span>
                          </p>
                          <p className="font-weight-bold">Saturday:
                            <br /><span className="font-weight-normal">After 6:30 pm Mass</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> {/* card */}
        </div>

        <Footer />
      </div>
    );
  }
};

About.propTypes = {
  match: PropTypes.object.isRequired,
};

export default About;
