import React from 'react';
import uuidv1 from 'uuid/v1';
import PropTypes from 'prop-types';

const Carousel = (props) => {
  const thumbImgData = props.carouselData.map((data, index) => {
    console.log(data.cardBackground, 'data');
    return (
      <li className={ index === 0 ? 'active' : '' } data-target="#carousel-example-generic4" data-slide-to={ index } key={ uuidv1() }>
        <img alt="image_1" src={ data.cardBackground } />
      </li>
    );
  });

  const slideShowData = props.carouselData.map((data, index) => {
    console.log(data.carouselBackground, 'data');
    return (
      <div className={ index === 0 ? 'carousel-item active' : 'carousel-item' } key={ uuidv1() }>
        <img className="d-block img-fluid" alt="..." src={ data.carouselBackground } />
        <div className="carousel-caption">
          <h3>{data.heading}</h3>
          <p>{data.caption}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="carousel-container" id="ms-navbar">
      <div className="ms-carousel ms-carousel-thumb carousel slide" id="carousel-example-generic4" data-ride="carousel">
        <ol className="carousel-indicators carousel-indicators-tumbs">
          {thumbImgData}
        </ol>
        <div className="carousel-inner" role="listbox">
          {slideShowData}
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  carouselData: PropTypes.array.isRequired,
};

export default Carousel;
