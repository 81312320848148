import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

class NavMenuItem extends Component {
  constructor(props) {
    super(props);
    this.NavSubMenuItemArr = [];
    this.state = {
      show: false,
      className: ''
    };
    this.NavSubMenuItemArr = props.subMenuItems && props.subMenuItems.map(subMenuItem => {
      return (
        <NavMenuItem key={ uuidv1() } name={ subMenuItem.name } active={ subMenuItem.active ? subMenuItem.active : false } url={ subMenuItem.url } subMenuItems={ subMenuItem.subMenuItem } isDropDown={ subMenuItem.isDropDown } isSubItem={ subMenuItem.isSubItem }/>
      );
    });
  }

  static defaultProps = {
    isDropDown: false,
    isSubItem: false,
  };

  updateClassName(_active, _show, _isDropDown) {
    const activeClass = _active ? 'active' : '';
    const showClass = this.props.subMenuItems && _show ? 'show' : '';
    const dropdownClass = _isDropDown ? 'dropdown' : '';

    return _isDropDown && !this.props.isSubItem ? `nav-item ${ activeClass } ${ showClass } ${ dropdownClass }` : this.props.isSubItem ? 'dropdown-submenu': '';
  }

  componentWillMount() {
    this.setState({ className: this.updateClassName(this.props.active, this.state.show, this.props.isDropDown) });
  }

  onMouseEnter = e => {
    this.setState({ show: true }, () => {
      this.setState({ className: this.updateClassName(this.props.active, this.state.show, this.props.isDropDown) });
    });
  };

  onMouseLeave = e => {
    this.setState({ show: false }, () => {
      this.setState({ className: this.updateClassName(this.props.active, this.state.show, this.props.isDropDown) });
    });
  };

  isSubMenuItem(_subMenuItem) {
    if (_subMenuItem && _subMenuItem.length > 0) {
      return (
        <ul className={ this.props.isSubItem ? `dropdown-menu dropdown-menu-left ${ this.state.show ? 'display-block' : '' }` : 'dropdown-menu' }>
          {_subMenuItem}
        </ul>
      );
    }
    return false;
  }

  render() {
    const className = this.props.isDropDown ? this.props.isSubItem ? 'dropdown-item has_children': 'nav-link dropdown-toggle animated fadeIn animation-delay-7' : 'dropdown-item';
    return (
      <li onMouseEnter={ this.onMouseEnter } onMouseLeave={ this.onMouseLeave } className={ this.props.isNavLink ? 'nav-item': this.state.className }>
        <Link to={ this.props.url ? this.props.url : '' } className={ (!this.props.isDropDown && !this.props.isSubItem && this.props.isNavLink) ? 'nav-link animated fadeIn animation-delay-7' : className }>
          {(!this.props.isDropDown || this.props.isSubItem) && !this.props.isNavLink && <i className="zmdi zmdi-view-compact" />} {this.props.name}
          { (this.props.isDropDown && !this.props.isSubItem) && <i className="zmdi zmdi-chevron-down" />}
        </Link>
        {this.isSubMenuItem(this.NavSubMenuItemArr)}
      </li>
    );
  }
}

NavMenuItem.propTypes = {
  isDropDown: PropTypes.bool.isRequired,
  isSubItem: PropTypes.bool.isRequired,
  isNavLink: PropTypes.bool,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  subMenuItems: PropTypes.array,
};

export default NavMenuItem;
