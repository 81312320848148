import React from 'react';
import APPCONFIG from '../app-config';
import { Header, NavBar, Footer } from '../components';

class BaseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...APPCONFIG
    };
  }

  _navbarElem = React.createRef();

  get navbarElem() {
    return this._navbarElem.current;
  }

  render() {
    return (
      <div className="home">
        <Header />
        <NavBar ref={ this._navbarElem } />
        {this.renderPage()}
        <Footer />
      </div>
    );
  }

}

export default BaseView;