import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { SmallCarousel } from '.';

const HomeTiles = (props) => {
  const getList = (textObjArr) => {
    return _.map(textObjArr, (obj, index) => <li key={ obj.id }>{obj.short_lines}</li>);
  };
  const cardsElems = props.cards.map((cardObj, index) => {
    const cardWidth = cardObj.cardWidth ? cardObj.cardWidth : 4;
    return (
      <div className={ `col-md-${ cardWidth }` } key={ `home_${ index }` }>
        <div className={ `card card-primary ${ cardObj.cardClass }` }>
          <div className="card-header">
            <h3 className="card-title">{cardObj.cardTitle}</h3>
          </div>
          {cardObj.cardImageObj && <img src={ cardObj.cardImageObj.imageSrc } alt={ cardObj.cardImageObj.altText } width={ cardObj.cardImageObj.width } height={ cardObj.cardImageObj.height } style={ cardObj.cardImageObj.style }/>}
          <div className="card-body scroll" style={ { background: cardObj.cardBackground ? cardObj.cardBackground : null } }>
            {cardObj.cardType === 'text' && <p className="card-text">{cardObj.cardText}</p>}
            {cardObj.cardType === 'html' && <p className="card-text" dangerouslySetInnerHTML={ { __html: cardObj.cardHTML } }></p>}
            {cardObj.cardType === 'list' && <p className="card-text">{getList(cardObj.cardData)}</p>}
            {cardObj.cardType === 'carousel' ? <SmallCarousel data={ cardObj.cardData } /> : null}
          </div>
          <Link to={ cardObj.buttonLink ? cardObj.buttonLink : '' } className="btn btn-raised btn-info" style={ { visibility: cardObj.cardButton ? 'visible' : 'hidden' } }><i className="zmdi zmdi-airplane"></i> {cardObj.buttonText}</Link>
        </div>
      </div>
    );
  });

  return (
    <div className="row tile-container">
      <div className="card-group justify-content-center">
        {cardsElems}
      </div>
    </div>
  );
};

HomeTiles.propTypes = {
  cards: PropTypes.array.isRequired,
};

export default HomeTiles;
