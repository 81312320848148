import React, { Fragment } from 'react';
import _ from 'lodash';
import TeamCard from './TeamCard';
import Collapsable from './Collapsable';
import uuidv1 from 'uuid/v1';

const Teampanel = (props) => {
  const CardElems = _.map(props.cards, (cardItem) => {
    const { name, post_name, post_subdetails, photo_path, social_networking, cardWidth } = cardItem;
    return (<TeamCard
      key={ uuidv1() }
      name={ name }
      post_name={ post_name }
      post_subdetails={ post_subdetails ? post_subdetails : '' }
      photo_path={ photo_path }
      social_networking={ social_networking }
      cardWidth={ cardWidth ? cardWidth : 4 }
    />);
  });
  return (
    <Fragment>
      <br />
      <Collapsable display={props.display} div_id={props.div_id} href={props.href} panel_title={props.panel_title}/>
    </Fragment>
  );
};

export default Teampanel;