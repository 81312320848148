import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import API from '../apiRoutes';
import BaseView from './BaseView';
import { Header, NavBar, Footer, AssetHelper } from '../components';
import events from '../data/events';

const { getContentFrom } = AssetHelper;
const { BASE_URI, ROUTES } = API;

class ParishLife extends BaseView {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      collapse_add: false,
      collapse_view: true,
      annoucementsData: {},
      marriageBannsData: {},
      goldenJubileeData: {},
      anniversaryData: {},
      obituariesData: {},
    };
  }

  tabClick(elemId) {
    this.removeActiveClass('li[class="nav-item"] > a[id*=tabLink_]');
    this.removeActiveClass('div[id*="tab_"]');
    // this.removeActiveClass('li[class="nav-item"] > a[id*=tabLink_]');
    this.addActiveClass(elemId);
  }

  removeActiveClass(selector) {
    const htmlNodeList = document.querySelectorAll(selector);
    for (const node of htmlNodeList) {
      node.classList.remove('active');
      node.classList.remove('show');
    }
  }

  addActiveClass(elemId) {
    const element = `#tab_${ elemId }`;
    document.querySelector(element).classList.add('active');
    document.querySelector(element).classList.add('show');
    document.querySelector(`#tabLink_${ elemId }`).classList.add('active');
  }

  componentDidMount() {
    document.title = `${ this.state.DEFAULT_TITLE } :: Parish Life`;

    this.tabClick(this.props.match.params.tab);

    getContentFrom(`${ BASE_URI }${ ROUTES.ANNOUNCEMENTS.GET }`).then((annoucementsData) => {
      this.setState({ annoucementsData });
    });

    // getContentFrom(`${ BASE_URI }${ ROUTES.MARRIAGE_BANNS.GET }`).then((marriageBannsData) => {
    //   this.setState({ marriageBannsData });
    // });

    // getContentFrom(`${ BASE_URI }${ ROUTES.GOLDEN_JUBILEE.GET }`).then((goldenJubileeData) => {
    //   this.setState({ goldenJubileeData });
    // });

    // getContentFrom(`${ BASE_URI }${ ROUTES.WEDDING_ANNIVERSARY.GET }`).then((anniversaryData) => {
    //   this.setState({ anniversaryData });
    // });

    // getContentFrom(`${ BASE_URI }${ ROUTES.OBITUARIES.GET }`).then((obituariesData) => {
    //   this.setState({ obituariesData });
    // });
  }

  componentWillUpdate(props, state) {
    if (props.match.params.tab !== this.props.match.params.tab) {
      this.tabClick(props.match.params.tab);
    }
  }

  render() {
    return (
      <div className="about">
        <Header />
        <NavBar />
        <br />
        <div className="container">
          <div className="card">
            {/* Nav tabs */}
            <ul className="nav nav-tabs nav-tabs-full nav-tabs-5 shadow-2dp shadow-2dp" role="tablist">
              <li className="nav-item"><Link id="tabLink_announcements" className="nav-link withoutripple" to="/parish_life/announcements" aria-controls="announcements"><i className="zmdi zmdi-email" /><span className="d-none d-sm-inline">Announcements</span></Link></li>
              <li className="nav-item"><Link id="tabLink_upcoming_events" className="nav-link withoutripple" to="/parish_life/upcoming_events" aria-controls="announcements"><i className="zmdi zmdi-email" /><span className="d-none d-sm-inline">Upcoming Events</span></Link></li>
              {/* <li className="nav-item"><Link id="tabLink_marriage_banns" className="nav-link withoutripple" to="/parish_life/marriage_banns" aria-controls="marriage_banns"><i className="zmdi zmdi-time-restore-setting" /> <span className="d-none d-sm-inline">Marriage Banns</span></Link></li>
              <li className="nav-item"><Link id="tabLink_golden_jubilee" className="nav-link withoutripple" to="/parish_life/golden_jubilee" aria-controls="golden_jubilee"><i className="zmdi zmdi-account" /> <span className="d-none d-sm-inline">Golden Jubilee</span></Link></li>
              <li className="nav-item"><Link id="tabLink_anniversary" className="nav-link withoutripple" to="/parish_life/anniversary" aria-controls="anniversary"><i className="zmdi zmdi-home" /> <span className="d-none d-sm-inline">Weddings Anniversary</span></Link></li>
              <li className="nav-item"><Link id="tabLink_obituaries" className="nav-link withoutripple" to="/parish_life/obituaries" aria-controls="obituaries"><i className="zmdi zmdi-time" /> <span className="d-none d-sm-inline">Obituaries</span></Link></li> */}
            </ul>
            <div className="card-body">
              {/* Tab panes */}
              <div className="tab-content">
                {/* History Tab */}
                <div role="tabpanel" className="tab-pane fade" id="tab_announcements">
                  <h2 className="text-center">Announcements</h2>
                  <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
                    {this.createCollapses(this.state.annoucementsData)}
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="tab_upcoming_events">
                  <h2 className="text-center">Upcoming Events</h2>
                  <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
                    {this.createCollapses(events)}
                  </div>
                </div>
                {/* <div role="tabpanel" className="tab-pane fade" id="tab_marriage_banns">
                  <h2 className="text-center">Marriage Banns</h2>
                  <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
                    {this.createCollapses(this.state.marriageBannsData)}
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="tab_golden_jubilee">
                  <h2 className="text-center">Golden Jubilee</h2>
                  <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
                    {this.createCollapses(this.state.goldenJubileeData)}
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="tab_anniversary">
                  <h2 className="text-center">Weddings Anniversary</h2>
                  <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
                    {this.createCollapses(this.state.anniversaryData)}
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="tab_obituaries">
                  <h2 className="text-center">Obituaries</h2>
                  <div className="ms-collapse" id="accordion2" role="tablist" aria-multiselectable="true">
                    {this.createCollapses(this.state.obituariesData)}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }

  createCollapses(collapseData) {
    const collapses = _.map(collapseData, (dataObj, index) => {
      return (
        <div key={ dataObj.id } className="mb-0 card card-primary">
          <div className="card-header" role="tab" id="headingOne2">
            <h4 className="card-title">
              <a className="withripple text-truncate" role="button" data-toggle="collapse" data-parent="#accordion2" href={ `#collapse_${ index }` } aria-expanded="true" aria-controls={ `collapse_${ index }` }>
                <i className="zmdi zmdi-email"></i> {dataObj?.short_lines}
              </a>
            </h4>
          </div>
          <div id={ `collapse_${ index }` } className="card-collapse collapse show" role="tabpanel" aria-labelledby="headingOne2">
            <div className="card-body text-center">
              {dataObj.details?.bodyText}
              {dataObj?.eventDetailPdf && <a href={ dataObj.eventDetailPdf } target="_blank" rel="noreferrer"><img src={ dataObj?.eventDetailPdfThumbnail } alt={ dataObj?.eventPosterAltText } style={ dataObj?.eventThumbnailStyleObj }></img></a>}
              {dataObj?.eventPosterURL &&
                <a href={ dataObj.eventPosterURL } target="_blank" rel="noreferrer">
                  <img src={ dataObj.eventPosterURL } alt={ dataObj?.eventPosterAltText } style={ dataObj?.eventPosterStyleObj }></img>
                </a>
              }
            </div>
          </div>
        </div>
      );
    });
    return collapses;
  }
}

export default ParishLife;