import React from 'react';
import PropTypes from 'prop-types';

const FormerPriestCard = (props) => {
  const {
    id,
    formerPriest_duration,
    formerPriest_Name,
    img_path
  } = props;

    return (
      <div className="col-md-4" id={ id }>
        <div className="card wow animated fadeInUp">
          <div className="withripple zoom-img">
            <img src={ img_path } alt="" className="img-fluid"/>
          </div>
          <div className="card-body overflow-hidden text-center">
            <h4 className="color-success">{formerPriest_Name }</h4>
            <p>{ formerPriest_duration }</p>
          </div>
        </div>
      </div>
    );
};

FormerPriestCard.propTypes = {
  formerPriest_Name: PropTypes.string.isRequired,
};

export default FormerPriestCard;