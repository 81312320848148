import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const mediaTeam = {
    team_array: [
        {
            id: 1,
            div_id: 'collapseOne2',
            panel_title: 'Media Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car1-thumb.png'),
                    // img: imagePath('demo/car1.png'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Anish Thottungal',
                    post_name: 'Member',
                    photo_path: imagePath('team/anish_joy.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Jerry Joy',
                    post_name: 'Member',
                    photo_path: imagePath('team/jerry_joy.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Joby Antony',
                    post_name: 'Member',
                    photo_path: imagePath('team/joby_antony.jpg'),
                    social_networking: 'hidden',
                },
            ],
        },
    ]
};

export default mediaTeam;