const AssetHelper = {
  imagePath: (path) => {
    return `${ process.env.PUBLIC_URL }/assets/img/${ path }`;
  },
  videoPath: (path) => {
    return `${ process.env.PUBLIC_URL }/assets/videos/${ path }`;
  },
  getContentFrom: (url) => {
    return fetch(url)
      .then(r => r.json());
  }
};

export default AssetHelper;