import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SideNavBar from '../components/SideNavBar';
import AssetHelper from '../components/AssetHelper';
const { imagePath } = AssetHelper;

class Header extends Component {
  constructor() {
    super();
    this.state = {
      menuStatus: 'close'
    };
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick() {
    switch (this.state.menuStatus) {
      default:
        this.setState({
          menuStatus: 'close',
        });
        break;
      case 'close':
        this.setState({
          menuStatus: 'open',
        });
        break;
    }
  }

  render() {
    return (
      <Fragment>
        <header className="ms-header ms-header-primary">
          <div className="ms-header-container">
            <div className="container container-full" style={ { backgroundImage: imagePath('/headerBackground.png') } }>
              <div className="ms-title">
                <Link to="/home">
                  {/* <img src="assets/img/email/logo_blue.png" alt=""> */}
                  {/* <span className="ms-logo animated zoomInDown animation-delay-5">M</span> */}
                  <h1 className="animated fadeInRight animation-delay-6">
                    <span>St. Thomas</span> Cathedral <br /> and Pilgrim Centre
                  </h1>
                </Link>
              </div>
              <div className="header-right">
                <div className="share-menu">
                  <ul className="share-menu-list">
                    <li className="animated fadeInRight animation-delay-3">
                      <button className="btn-circle btn-google">
                        <i className="zmdi zmdi-google" />
                      </button>
                    </li>
                    <li className="animated fadeInRight animation-delay-2">
                      <Link to="#" className="btn-circle btn-facebook">
                        <i className="zmdi zmdi-facebook" />
                      </Link>
                    </li>
                    <li className="animated fadeInRight animation-delay-1">
                      <Link to="#" className="btn-circle btn-twitter">
                        <i className="zmdi zmdi-twitter" />
                      </Link>
                    </li>
                  </ul>
                  <Link to="#" onClick={ (event) => event.preventDefault() } className="btn-circle btn-circle-primary no-focus animated zoomInDown animation-delay-7">
                    <i className="zmdi zmdi-share" />
                  </Link>
                </div>
                <Link to="#" onClick={ (event) => event.preventDefault() } className="btn-circle btn-circle-primary no-focus animated zoomInDown animation-delay-8" data-toggle="modal" data-target="#ms-account-modal">
                  <i className="zmdi zmdi-account" />
                </Link>
                <form className="search-form animated zoomInDown animation-delay-9">
                  <input id="search-box" type="text" className="search-input" placeholder="Search..." name="q" />
                  <label htmlFor="search-box">
                    <i className="zmdi zmdi-search" />
                  </label>
                </form>
                <button onClick={ this.handleClick } className="btn-ms-menu btn-circle btn-circle-primary animated zoomInDown animation-delay-10">
                  <i className="zmdi zmdi-menu" />
                  {console.log(this.state.menuStatus)}
                </button>
              </div>
            </div>
          </div>
        </header>
        <SideNavBar
          menuStatus={ this.state.menuStatus }
        />
      </Fragment>
    );
  }
}

export default Header;
