import { AssetHelper } from '../components';
const { imagePath } = AssetHelper;

export const carousel = {
    carouselData: [
        {
            heading: 'The Outside View',
            // caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            carouselBackground: imagePath('banner/church_outside_view.jpg'),
            cardBackground: imagePath('banner/church_outside_view-thumb.jpg'),
        },
        {
            heading: 'The Altar',
            // caption: 'Also to the left side of the alter the Santuary of Relics',
            carouselBackground: imagePath('banner/alter.jpg'),
            cardBackground: imagePath('banner/alter-thumb.jpg'),
        },
        {
            heading: 'Grotto',
            // caption: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            carouselBackground: imagePath('banner/groto.jpg'),
            cardBackground: imagePath('banner/groto-thumb.jpg'),
        },
    ]
};