import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Page_Home from '../views/Home';
import Page_Message from '../views/Message';
import Page_ParishLife from '../views/ParishLife';
import Page_Prayers from '../views/Prayers';
import Page_About from '../views/About';
import Page_Associations from '../views/Associations';
import Page_GetInTouch from '../views/Get-in-touch';
import Page_Gallery from '../views/Gallery';
import Page_404 from '../views/404';
import Page_500 from '../views/500';
import '../css/App.css';

class App extends Component {
  render() {
    return (
      <div className="ms-site-container">
        <div className="bg-video">
          {/* <video autoPlay muted loop id="myVideo" width="100%">
            <source src={ videoPath('bg_video1.mp4') } type="video/mp4" />
          </video> */}
        </div>
        <HashRouter basename="/cathedral-site">
          <Switch>
            <Route path="/home" exact component={ Page_Home } />           
            <Route path="/message/:from/" exact component={ Page_Message } />
            <Route path="/about/:tab/:section?" exact component={ Page_About } />
            <Route path="/parish_life/:tab" exact component={ Page_ParishLife } />
            <Route path="/prayers" component={ Page_Prayers } />
            <Route path="/associations/:tab?" exact component={ Page_Associations } />
            <Route path="/get-in-touch/:tab/" exact component={ Page_GetInTouch } />
            <Route path="/gallery" exact component={ Page_Gallery } />
            <Route path="/404" exact component={ Page_404 } />
            <Route path="/500" exact component={ Page_500 } />
            <Redirect from="/" to="/home" />
          </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
