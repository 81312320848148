import React from 'react';
import _ from 'lodash';
import FormerPriestCard from './FormerPriestCard';

const FormerPriest = (props) => {
  const { data } = props;
  const { formerPriest_array } = data;
  formerPriest_array.reverse();
  const formerpriestElems = _.map(formerPriest_array, (PriestCards) =>
    <FormerPriestCard
      key={ PriestCards.id }
      formerPriest_duration={ PriestCards.formerPriest_duration }
      formerPriest_Name={ PriestCards.formerPriest_Name }
      img_path={ PriestCards.img_path }
    />
  );

  return (
    <div className="row">
      { formerpriestElems }
    </div>
  );
};

export default FormerPriest;
