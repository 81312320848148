import React from 'react';
import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import API from '../apiRoutes';
import BaseView from './BaseView';
import { AssetHelper } from '../components';

const { getContentFrom } = AssetHelper;
const { BASE_URI, ROUTES } = API;

class Prayers extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      prayersObj: {},
      cardsArr: []
    };
  }

  loadPrayerTiles() {
    const { prayersObj } = this.state;
    return _.map(prayersObj, ({ prayer_name, prayer_type, en_filename: eng_filepath, mal_filename: mal_filepath }) => (this.createPrayerTileElem({ prayer_name, prayer_type, eng_filepath, mal_filepath })));
  }

  componentWillMount() {
    document.title = `${ this.state.DEFAULT_TITLE } :: Home`;
    getContentFrom(`${ BASE_URI }${ ROUTES.PRAYERS.GET }?from=${ this.props.match.params.from }`).then((prayersObj) => {
      this.setState({ prayersObj });
    });
  }

  createPrayerTileElem({ prayer_name, prayer_type, mal_filepath, eng_filepath }) {
    return (
      <div key={ uuidv1() } className="col-md-4">
        <div className="card">
          <div className="card-body">
            {prayer_name && <h4>{prayer_name}</h4>}
            {prayer_type && <p>{prayer_type}</p>}
            {eng_filepath && <a href={ eng_filepath } target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-info">English</a>}
            {mal_filepath && <a href={ mal_filepath } target="_blank" rel="noopener noreferrer" className="btn btn-raised btn-info">Malayalam</a>}
          </div>
        </div>
      </div>
    );
  }

  renderPage() {
    return (
      <div className="container">
        <div className="panel panel-default mt-2">
          <div className="panel-body">
            <div className="card-group justify-content-center">
              {this.loadPrayerTiles()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Prayers;