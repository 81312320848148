import React, { Fragment } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const SideSubMenuItem = (props) => {
  const { SubMenuItem } = props;
  const subMenuItem_array = SubMenuItem;
  const subMenuItemElems = _.map(subMenuItem_array, (subMenu) =>
    <li key={ subMenu.name }>
      <Link to={ subMenu.url }>{ subMenu.name }</Link>
    </li>
    );
    return (
      <Fragment>
        { subMenuItemElems }
      </Fragment>
    );
};

export default SideSubMenuItem;