import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import BaseView from './BaseView';
import { Header, Footer, NavBar } from '../components';

class Get_in_touch extends BaseView {
  constructor(props) {
    super(props);
    this.state = {};
    document.title = document.title + ' :: Get in touch';
  }

  _inputName = createRef();

  _inputPhoneNo = createRef();

  get inputName() {
    return this._inputName.current;
  }

  get inputPhoneNo() {
    return this._inputPhoneNo.current;
  }

  tabClick(elemId) {
    this.removeActiveClass('li[class="nav-item"] > a[id*=tabLink_]');
    this.removeActiveClass('div[id*="tab_"]');
    // this.removeActiveClass('li[class="nav-item"] > a[id*=tabLink_]');
    this.addActiveClass(elemId);
  }

  removeActiveClass(selector) {
    const htmlNodeList = document.querySelectorAll(selector);
    for (const node of htmlNodeList) {
      node.classList.remove('active');
      node.classList.remove('show');
    }
  }

  addActiveClass(elemId) {
    const element = `#tab_${ elemId }`;
    console.log(document.querySelector(element), 'document.querySelector(element)');
    document.querySelector(element).classList.add('active');
    document.querySelector(element).classList.add('show');
    document.querySelector(`#tabLink_${ elemId }`).classList.add('active');
  }

  componentDidMount() {
    console.log(this.props.match.params.tab, 'props.match.params.tab');
    this.tabClick(this.props.match.params.tab);
  }

  componentWillUpdate(props, state) {
    if (props.match.params.tab !== this.props.match.params.tab) {
      this.tabClick(props.match.params.tab);
      console.log(props.match.params.tab, 'props.match.params.tab');
    }
    console.log(props, state, 'props, state');
  }

  onSubmitHandler = (event) => {
    event.preventDefault();
    console.log('onSubmit', this.inputName.value, this.inputPhoneNo.value);
  };

  render() {
    return (
      <div className="get-in-touch">
        <Header />
        <NavBar />
        <br />
        <div className="container">
          <div className="card">
            {/* Nav tabs */}
            <ul className="nav nav-tabs nav-tabs-full nav-tabs-2 shadow-2dp" role="tablist">
              <li className="nav-item">
                <Link to="/get-in-touch/contact-us" id="tabLink_contact-us" className="nav-link withoutripple active" aria-controls="contact-us">
                  <i className="mr-1 zmdi zmdi-email zmdi-hc-fw" />
                  <span className="d-none d-sm-inline">Contact us</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/get-in-touch/prayer-request" id="tabLink_prayer-request" className="nav-link withoutripple" aria-controls="prayer-request">
                  <i className="mr-1 zmdi zmdi-receipt zmdi-hc-fw" />
                  <span className="d-none d-sm-inline">Prayer Request</span>
                </Link>
              </li>
            </ul>
            <div className="card-body">
              {/* Tab panes */}
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane fade active show" id="tab_contact-us">
                  <div className="animated fadeInUp" id="catechism" role="tablist" aria-multiselectable="true">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-8 col-lg-7">
                          <div className="card card-primary animated fadeInUp animation-delay-7">
                            <div className="ms-hero-bg-primary ms-hero-img-mountain">
                              <h2 className="text-center no-m pt-4 pb-4 color-white index-1">Contact us</h2>
                            </div>
                            <div className="card-body">
                              <form className="form-horizontal" onSubmit={ this.onSubmitHandler }>
                                <fieldset className="container">
                                  <div className="form-group row">
                                    <label htmlFor="name" autoComplete="false" className="col-lg-2 control-label">Name</label>
                                    <div className="col-lg-9">
                                      <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        required
                                        ref={ this._inputName }
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="inputEmail" autoComplete="false" className="col-lg-2 control-label">Phone No.</label>
                                    <div className="col-lg-9">
                                      <input
                                        id="phone_no"
                                        name="phone_no"
                                        type="number"
                                        className="form-control"
                                        placeholder="Phone No."
                                        required=""
                                        ref={ this._inputPhoneNo }
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="email" autoComplete="false" className="col-lg-2 control-label">Email</label>
                                    <div className="col-lg-9">
                                      <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        required=""
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="subject" autoComplete="false" className="col-lg-2 control-label">Subject</label>
                                    <div className="col-lg-9">
                                      <input
                                        id="subject"
                                        name="subject"
                                        type="text"
                                        className="form-control"
                                        placeholder="Subject"
                                        required=""
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="message" className="col-lg-2 control-label">Message</label>
                                    <div className="col-lg-9">
                                      <textarea
                                        id="message"
                                        name="message"
                                        className="form-control"
                                        rows="3"
                                        placeholder="Your message..."
                                        required=""
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="form-group row justify-content-end">
                                    <div className="col-lg-10">
                                      <input type="submit" className="btn btn-raised btn-primary" value="Send" />
                                      <input type="button" className="btn btn-danger" value="Cancel" />
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                          <div className="card card-primary animated fadeInUp animation-delay-7">
                            <div className="card-body">
                              <div className="text-center mb-2">
                                {/* <span className="ms-logo ms-logo-sm mr-1">St.</span> */}
                                <h3 className="no-m">
                                  <span>St. Thomas Cathedral and Pilgirm Center</span>
                                </h3>
                              </div>
                              <address className="no-mb">
                                <p>
                                  <i className="color-danger-light zmdi zmdi-pin mr-1"></i> Near Ganesh Ghat, Murbad Road, Kalyan West</p>
                                <p>
                                  <i className="color-warning-light zmdi zmdi-map mr-1"></i> Thane, Maharashtra – 421301</p>
                                <p>
                                  <i className="color-info-light zmdi zmdi-email mr-1"></i>
                                  <a href="mailto:joe@example.com">stthomas@kalyancathedral.org</a>
                                </p>
                                {/* <p>
                                  <i className="color-royal-light zmdi zmdi-phone mr-1"></i>0251 232 3946 </p>
                                <p>
                                  <i className="color-success-light fa fa-fax mr-1"></i>0251 230 3000</p> */}
                              </address>
                            </div>
                          </div>
                          <div className="card card-primary animated fadeInUp animation-delay-7">
                            <div className="card-header">
                              <h3 className="card-title">
                                <i className="zmdi zmdi-map"></i>Map</h3>
                            </div>
                            <iframe title="iframe" width="100%" height="340" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.935544777058!2d73.147666314455!3d19.241640651746184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be794243595c67f%3A0x270480dbba63f0a3!2sSt.+Thomas+Cathedral+And+Pilgrimage+Church!5e0!3m2!1sen!2sin!4v1466085624614"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="tab_prayer-request">
                  <div className="animated fadeInUp" id="prayer-request" role="tablist" aria-multiselectable="true">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-8 col-lg-7">
                          <div className="card card-primary animated fadeInUp animation-delay-7">
                            <div className="ms-hero-bg-primary ms-hero-img-mountain">
                              <h2 className="text-center no-m pt-4 pb-4 color-white index-1">Prayer Request</h2>
                            </div>
                            <div className="card-body">
                              <form className="form-horizontal">
                                <fieldset className="container">
                                  <div className="form-group row">
                                    <label htmlFor="inputEmail" autoComplete="false" className="col-lg-2 control-label">Name</label>
                                    <div className="col-lg-9">
                                      <input type="text" className="form-control" id="inputName" placeholder="Name" /> </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="inputEmail" autoComplete="false" className="col-lg-2 control-label">Email</label>
                                    <div className="col-lg-9">
                                      <input type="email" className="form-control" id="inputEmail" placeholder="Email" /> </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="inputEmail" autoComplete="false" className="col-lg-2 control-label">Phone No.</label>
                                    <div className="col-lg-9">
                                      <input type="number" className="form-control" id="inputEmail" placeholder="Phone No." /> </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="inputEmail" autoComplete="false" className="col-lg-2 control-label">Subject</label>
                                    <div className="col-lg-9">
                                      <input type="text" className="form-control" id="inputSubject" placeholder="Subject" /> </div>
                                  </div>
                                  <div className="form-group row">
                                    <label htmlFor="textArea" className="col-lg-2 control-label">Prayer Request</label>
                                    <div className="col-lg-9">
                                      <textarea className="form-control" rows="3" id="textArea" placeholder="Your Prayer Request..."></textarea>
                                    </div>
                                  </div>
                                  <div className="form-group row justify-content-end">
                                    <div className="col-lg-10">
                                      <button type="submit" className="btn btn-raised btn-primary">Submit</button>
                                      <button type="button" className="btn btn-danger">Cancel</button>
                                    </div>
                                  </div>
                                </fieldset>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-4 col-lg-5">
                          <div className="card card-primary animated fadeInUp animation-delay-7">
                            <div className="card-body">
                              <div className="text-center mb-2">
                                {/* <span className="ms-logo ms-logo-sm mr-1">St.</span> */}
                                <h3 className="no-m">
                                  <span>St. Thomas Cathedral and Pilgirm Center</span>
                                </h3>
                              </div>
                              <address className="no-mb">
                                <p>
                                  <i className="color-danger-light zmdi zmdi-pin mr-1"></i> Near Ganesh Ghat, Murbad Road, Kalyan West</p>
                                <p>
                                  <i className="color-warning-light zmdi zmdi-map mr-1"></i> Thane, Maharashtra – 421301</p>
                                <p>
                                  <i className="color-info-light zmdi zmdi-email mr-1"></i>
                                  <a href="mailto:joe@example.com">stthomas@kalyancathedral.org</a>
                                </p>
                                {/* <p>
                                  <i className="color-royal-light zmdi zmdi-phone mr-1"></i>0251 232 3946 </p>
                                <p>
                                  <i className="color-success-light fa fa-fax mr-1"></i>0251 230 3000</p> */}
                              </address>
                            </div>
                          </div>
                          <div className="card card-primary animated fadeInUp animation-delay-7">
                            <div className="card-header">
                              <h3 className="card-title">
                                <i className="zmdi zmdi-map"></i>Map</h3>
                            </div>
                            <iframe title="iframe" width="100%" height="340" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.935544777058!2d73.147666314455!3d19.241640651746184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be794243595c67f%3A0x270480dbba63f0a3!2sSt.+Thomas+Cathedral+And+Pilgrimage+Church!5e0!3m2!1sen!2sin!4v1466085624614"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> {/* card */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Get_in_touch;