import React from 'react';
import PropTypes from 'prop-types';

const TimelineCard = (props) => {
    const {
        year,
        date_month,
        event_name,
        event_description_name,
        event_description,
    } = props;

    return (
      <li className="ms-timeline-left-item animated wow fadeInUp" style={ { visibility: 'visible', animationName: 'fadeInUp' } }>
        <h4>{event_description_name}</h4>
        <div className="ms-timeline-left-left">
          <h4 className="color-primary">{event_name}</h4>
          <time className="ms-timeline-left-time">{date_month} {year}</time>
        </div>
        {/* <span className="ms-timeline-left-city"> {year}</span> */}
        <p>{event_description}</p>
        {/* <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Maecenas tempus tellus eget</li>
            <li>Donec pede justo ante</li>
          </ul> */}
      </li>
    );
};

TimelineCard.propTypes = {
    year: PropTypes.string.isRequired,
};

export default TimelineCard;