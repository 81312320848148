import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const youth = {
    team_array:[
        {
            id:1,
            div_id: 'collapseOne2',
            panel_title: 'Youth Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car3-thumb.png'),
                    // img: imagePath('demo/post3.jpg'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Joby Antony',
                    post_name: 'Animator',
                    photo_path: imagePath('team/joby_antony.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Felix Wilson',
                    post_name: 'President',
                    photo_path: imagePath('team/felix_wilson.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Divya Job',
                    post_name: 'Vice President',
                    photo_path: imagePath('team/divya_job.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Alisha George',
                    post_name: 'Secretary',
                    photo_path: imagePath('team/alisha_george.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Sunil Joseph',
                    post_name: 'Joint Secretary',
                    photo_path: imagePath('team/sunil_joseph.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Briana Rajan',
                    post_name: 'Treasurer',
                    photo_path: imagePath('team/briana_rajan.jpg'),
                    social_networking: 'hidden',
                },
            ],
        },
    ]
};

export default youth;