import React from 'react';
import NavMenuItem from './NavMenuItem';
import uuidv1 from 'uuid/v1';

const NavMenu = (props) => {
  const NavMenuItemElem = props.menuItems.map((menuItem) => <NavMenuItem key={ uuidv1() } active={ menuItem.active ? menuItem.active : false } name={ menuItem.name } subMenuItems={ menuItem.subMenuItem } isDropDown={ menuItem.isDropDown } isNavLink={ menuItem.isNavLink } url={ menuItem.url }/>);
  return (
    <div className="collapse navbar-collapse" id="ms-navbar">
      <ul className="navbar-nav">
        { NavMenuItemElem }
      </ul>
    </div>
  );
};

export default NavMenu;
