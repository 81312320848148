import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const catechismTeam = {
    team_array:[
        {
            id:1,
            div_id: 'collapseOne2',
            panel_title: 'Catechism Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car1-thumb.png'),
                    // img: imagePath('demo/car1.png'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Bijo Wilson',
                    post_name: 'Head Master',
                    photo_path: imagePath('team/bijo_wilson.jpeg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Bijo Joesph',
                    post_name: 'Assistant Head Master',
                    photo_path: imagePath('team/bijo_joseph.jpeg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Maria Francis',
                    post_name: 'Secretary',
                    photo_path: imagePath('team/maria_francis.jpg'),
                    social_networking: 'hidden',
                },
            ],
        },
    ]
};

export default catechismTeam;