const events = [
  {
    id: 'e264d617-71b4-4631-b39c-e63b3715c231',
    short_lines: 'St. Thomas Feast 2022',
    eventDate: '20 November 2022',
    showEventPopupOnLoad: true,
    eventPosterURL: 'http://kalyancathedral.org/resources/event_posters/Option%203%20A4%20Back%20Cover-%20St.Thomas%20Feast%20Poster2022.jpg',
    eventPosterStyleObj: {
      width: '100%',
      'max-width': '400px',
      'margin-left': '10px'
    },
    eventThumbnailStyleObj: {
      width: '100%',
      'max-width': '400px',
      'margin-left': '10px'
    },
    popupModalStyle: {
      width: '100%',
      'maxWidth': '600px',
    },
    eventPosterAltText: 'St. Thomas Feast 2022',
    eventDetailPdfThumbnail: 'http://kalyancathedral.org/resources/event_posters/Single%20File%20St.%20Thomas%20Feast.jpg',
    eventDetailPdf: 'http://kalyancathedral.org/resources/event_posters/Single%20File%20St.%20Thomas%20Feast%20-%202022%20Church%20Notice%20Final.pdf'
  }
];

export default events;