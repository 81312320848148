/* eslint-disable react/no-danger-with-children */
import React from 'react';
import PropTypes from 'prop-types';
import API from '../apiRoutes';
import { Header, Footer, NavBar, AssetHelper } from '../components';
import BaseView from './BaseView';

const { getContentFrom, imagePath } = AssetHelper;
const { BASE_URI, ROUTES } = API;

class Message extends BaseView {
  componentDidMount() {
    getContentFrom(`${ BASE_URI }${ ROUTES.MESSAGES.GET }?from=${ this.props.match.params.from }`).then((messageObj) => {
      this.setState({ messageObj });
    });
  }

  render() {
    if (!this.state.messageObj) {
      return false;
    }
    document.title = `${ this.state.DEFAULT_TITLE } :: ${ this.state.messageObj.headerTitle }`;

    return (
      <div className="message">
        <Header />
        <NavBar />
        <div className="main">
          <header className="ms-hero bg-primary-dark pb-4 pt-4 mb-6">
            <div className="container">
              <div className="text-center">
                <img className="header-image" src={ imagePath(this.state.messageObj.headerImage.src) } alt={ this.state.messageObj.headerImage.altText }></img>
                <h1 className="no-m ms-site-title color-white center-block ms-site-title-lg mt-2 animated zoomInDown animation-delay-5">
                  {this.state.messageObj.headerTitle}
                </h1>
                <p className="lead mt-2 animated zoomInDown animation-delay-5">{this.state.messageObj.headerSubTitle}</p>
              </div>
            </div>
          </header>
          <div className="container">
            <section className="ms-component-section bg-white">
              <div className="panel panel-primary">
                <div className="panel-body" dangerouslySetInnerHTML={ { __html: this.state.messageObj.useHTML ? this.state.messageObj.bodyHTML : null } }>{!this.state.messageObj.useHTML ? this.state.messageObj.bodyText : null}</div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

Message.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Message;