import React, { Fragment } from 'react';
import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import { Link } from 'react-router-dom';
import SideSubMenuItem from './SideSubMenuItem';

const SideNavMenu = (props) => {
    const { menuItem } = props;
    const menuItem_array = menuItem;
    const menuItemElems = _.map(menuItem_array, (menu) =>
      <ul key={ menu.id } className="ms-slidebar-menu" id="slidebar-menu" role="tablist" aria-multiselectable="true">
        <li className="card" role="tab" id="sch1">
          { menu.isDropDown === false ? 
            <Link to={ menu.url } className="link" >
              <i className="zmdi zmdi-view-compact"></i>{ menu.name }
            </Link>
          : 
            <Fragment>
              <a className="collapsed" role="button" data-toggle="collapse" href={ `#sc${ menu.id }` } aria-expanded="false" aria-controls={ `#sc${ menu.id }` }>
                <i className="zmdi zmdi-collection-image-o"></i> {menu.name} 
              </a>
              <ul id={ `sc${ menu.id }` } className="card-collapse collapse" role="tabpanel" aria-labelledby={ `#sch${ menu.id }` } data-parent="#slidebar-menu">
                <SideSubMenuItem key={ uuidv1() } SubMenuItem={ menu.subMenuItem } />
              </ul>
            </Fragment>
            }
        </li>
      </ul>
    );
    return(
      <Fragment>
        <ul className="ms-slidebar-menu" id="slidebar-menu" role="tablist" aria-multiselectable="true">
          <li className="card" role="tab" id="sch1">
            <a className="link" href="#/cathedral-site/home">
              <i className="zmdi zmdi-view-compact"></i>
              Home
            </a>
          </li>
        </ul>
        {menuItemElems}
      </Fragment>
    );
};

export default SideNavMenu;