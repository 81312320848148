import React from 'react';
import _ from 'lodash';
import TeamPanel from './TeamPanel';
import uuidv1 from 'uuid/v1';

const Team = (props) => {
  const { data, selectedSection } = props;
  const { team_array } = data;
  const PanelElems = _.map(team_array, (teamPanel) =>
    <TeamPanel
      key= { uuidv1() } 
      div_id={ teamPanel.div_id }
      panel_title={ teamPanel.panel_title }
      href={ teamPanel.href }
      display={ teamPanel.div_id === selectedSection ? 'show' : teamPanel.display }
      cards={ teamPanel.teams }
    />
  );

return (
  <div>
    {PanelElems}
  </div>
);
};

export default Team;