const API = {
    BASE_URI: 'http://api.kalyancathedral.org/public/api',
    ROUTES: {
        ANNOUNCEMENTS: {
            GET: '/annoucements/get'
        },
        MESSAGES: {
            GET: '/messages/get',
        },
        MARRIAGE_BANNS: {
            GET: '/marriage-bans/get'
        },
        GOLDEN_JUBILEE: {
            GET: '/golden-jubilee/get'
        },
        WEDDING_ANNIVERSARY: {
            GET: '/wedding-anniversary/get'
        },
        OBITUARIES: {
            GET: '/obituaries/get'
        },
        PRAYERS: {
            GET: '/prayers/get'
        }
    }

};

export default API;