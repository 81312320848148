import React from 'react';
import { Link } from 'react-router-dom';

const NavBrand = (props) => {
  return (
    <div className="navbar-header">
      <Link className="navbar-brand" to="/home">
        {/* <span className="ms-logo ms-logo-sm">M</span> */}
        <span className="ms-title">
          <strong>St. Thomas </strong> Cathedral
        </span>
      </Link>
    </div>
  );
};

export default NavBrand;
