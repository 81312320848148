import { AssetHelper } from '../components';

const { imagePath } = AssetHelper;

const pithruvedi = {
    team_array:[
        {
            id:1,
            div_id: 'collapseOne2',
            panel_title: 'Pithruvedi Team',
            href: '#collapseOne2',
            display: 'show',
            carousels: [
                {
                    // thumbImg: imagePath('demo/car3-thumb.png'),
                    // img: imagePath('demo/paris.jpg'),
                    title: 'Lorem ipsum dolor',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                }
            ],
            teams: [
                {
                    name: 'Jose A D',
                    post_name: 'Animator',
                    photo_path: imagePath('team/jose_a_d.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Ajay Thomas',
                    post_name: 'President',
                    photo_path: imagePath('team/ajay_thomas.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Rajan Mathew',
                    post_name: 'Vice President',
                    photo_path: imagePath('team/rajan_mathew.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Joseph K Mathoor',
                    post_name: 'Secretary',
                    photo_path: imagePath('team/joseph_k_mathoor.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Thomas C K',
                    post_name: 'Joint Secretary',
                    photo_path: imagePath('team/thomas_c_k.jpg'),
                    social_networking: 'hidden',
                },
                {
                    name: 'Joy K P',
                    post_name: 'Treasurer',
                    photo_path: imagePath('team/joy_k_p.jpeg'),
                    social_networking: 'hidden',
                },
            ],
        },
    ]
};

export default pithruvedi;