const timeline = {
    event_array: [
        {
            id:'1',
            event_name:'Started in 1988',
            date_month:'15th September',
            year:'1988', 
            event_description_name:'Fr. George Edakkalathur took charge in 1988', 
            event_description:'On 15th September, 1988, Fr. George Edakkalathur took charge of Kalyan East, Kalyan West, Dombivli and Ambernath and on 5th January, 1989 he was appointed Parish Priest of Kalyan West and Dombivli. Regular Sunday Mass at our Lady of Lourdes Church at 10.30 AM and on festival days at Holy Cross Convent School was started from July 1990.'
        },
        {
            id:'2',
            event_name:'1990',
            date_month:'21st September', 
            year:'1990', 
            event_description_name:'Fr. Varghese Manavalan was appointed', 
            event_description:'On July, 21st, Fr. George Edakkalathur returned to Kerala and Fr. Varghese Manavalan was appointed the Parish Priest of Kalyan East and Kalyan West with his residence at Sangeetha Apartment, Karnik Road. He started weekly Holy Mass, Catechism, Youth Activities besides weekly Mass at NRC Mohane and Bhiwandi.'
        },
        {
            id:'3',
            event_name:'1991',
            date_month:'23rd September',
            year:'1991', 
            event_description_name:'The present plot was purchased', 
            event_description:'On 23rd April, 1991, the present plot was purchased and in 1992 Easter and Christmas Masses were celebrated in a Shamiana.'
        },
        {
            id:'4',
            event_name:'1993',
            date_month:'7th March',
            year:'1993', 
            event_description_name:'Mar Paul Chittilappilly consecrated a temporary tent', 
            event_description:'On 7th March, 1993, Mar Paul Chittilappilly consecrated a temporary tent there and offered Holy Mass.'
        },
        {
            id:'5',
            event_name:'1994',
            date_month:'8th January',
            year:'1994', 
            event_description_name:'Fr. Joseph Vattakuzhy took charge in 1994', 
            event_description:'On 8th January, 1994, Fr. Varghese Manavalan returned to Ernakulam and Fr. Joseph Vattakuzhy took charge as Parish Priest. From June, 1994, regular Catechism classes were started on Sundays at Holy Cross Convent School.'
        },
        {
            id:'6',
            event_name:'1995',
            date_month:'31st March',
            year:'1995', 
            event_description_name:'Official residence for Parish Priest', 
            event_description:'On 31st March, 1995, two adjacent flats were bought at Ballaleshwar Apartments which became the official residence of the Parish Priest from 31st June, 1995.'
        },
        {
            id:'7',
            event_name:'1996',
            date_month:'31st March',
            year:'1996', 
            event_description_name:'Temporary tent was renovated into a Church', 
            event_description:'On 31st March, 1996 the temporary tent was renovated into a Church with Holy Mass by His Excellency Mar Paul Chittilappilly with regular Masses. A semi permanent structure with Altar was built on 27th June, 1997 and this was used as a Church till May, 2009.'
        },
        {
            id:'8',
            event_name:'1998',
            date_month:'9th February',
            year:'1998', 
            event_description_name:'Fr. Sunny Perumpuzha took charge in 1998', 
            event_description:'On 9th February, 1998, Fr. Joseph Vattakuzhy returned to Kothamangalam and Fr. Sunny Perumpuzha took charge as Acting Vicar and on 17th June as Vicar. The Sacristy, Front Varanda, Store Room, Granite Cross and Flag post were built during this time.'
        },
        {
            id:'9',
            event_name:'1999',
            date_month:'27th May',
            year:'1999', 
            event_description_name:'Rev. Sisters of S.H. Convent', 
            event_description:'From 1988, Rev. Sisters of S.H. Convent, Asangaon, helped us in conducting Sunday School and other religious activities. Daughters of Sacred Heart of Jesus convent (DSHJ) started their activities in our Parish on 27th May, 1999 at a flat in Masoba Maidan and on 16th February, 2004, shifted to their own premises at Purandhare Colony in Santoshi Mata Road.'
        },
        {
            id:'10',
            event_name:'2001',
            date_month:'26th January',
            year:'2001', 
            event_description_name:'Fr. George Chollanal took charge in 2001', 
            event_description:'N/A'
        },
        {
            id:'11',
            event_name:'2002',
            date_month:'15th December',
            year:'2002', 
            event_description_name:'The Holy Relic of St. Thomas was brought from Orthona', 
            event_description:'The Holy Relic of St. Thomas was brought from Orthona, Italy by our Bishop Mar Thomas Elevanal and on 15th December 2002, Cardinal Varkey Vithayathil, the Major Arch Bishop of Syro Malabar Church declared Kalyan West Parish as an Eparchial Pilgrim Centre of St. Thomas in the Eparchy of Kalyan Diocese.'
        },
        {
            id:'12',
            event_name:'2004',
            date_month:'26th January',
            year:'2004', 
            event_description_name:'Fr. Thomas Thaikkoottathil took in 2004', 
            event_description:'On 26th January 2004, Fr.George Chollanal returned to Pala and on the same day Fr. Thomas Thaikkoottathil took over as Parish Priest and Rector of the Pilgrim Centre.'
        },
        {
            id:'13',
            event_name:'2005',
            date_month:'27th July',
            year:'2005', 
            event_description_name:'In July 2005 our Church was submerged under 20 feet water', 
            event_description:'On 27th July 2005, our Church was submerged under 20 feet water, the worst in 100 years. The Parishioners under guidance and leadership of Fr. Thomas Thaikkoottathil and Fr. George Kavukkat took lead in getting relief and succor to a great number of affected people in and around Kalyan.'
        },
        {
            id:'14',
            event_name:'2007',
            date_month:'6th June',
            year:'2007', 
            event_description_name:'Fr. Shaji Parickappillil took charge in 2007', 
            event_description:'On 6th June, 2007, Fr. Thomas Thaikkoottathil was transferred and Fr. Shaji Parickappillil took over as the Parish Priest and Rector of Kalyan.'
        },
        {
            id:'15',
            event_name:'2015',
            date_month:'15th August ',
            year:'2015', 
            event_description_name:'Laying of foundation stone for the Cathedral and Pilgrim Centre', 
            event_description:'His Excellency Mar Thomas Elevanal laid foundation stone for the Cathedral and Pilgrim Centre on 30th March, 2008. The construction work of the church began on 19th January, 2009.'
        },
        {
            id:'16',
            event_name:'2009',
            date_month:'20th May',
            year:'2009', 
            event_description_name:'Fr. Jose Vazhakalayil took charge in 2009', 
            event_description:'On 20th May, 2009, Fr. Shaji Parickappallil was transferred from this parish for higher studies. On 20th May, 2009, Fr. Jose Vazhakalayil took charge as Parish Priest & Rector and a temporary shed was blessed on the same evening.'
        },
        {
            id:'17',
            event_name:'2009',
            date_month:'30th August',
            year:'2009', 
            event_description_name:'Blessing the ground floor hall', 
            event_description:'On 30th August, 2009, His Excellency Mar Thomas Elavanal blessed the ground floor Hall and the Holy Mass and other allied activities were shifted to the Hall.'
        },
        {
            id:'18',
            event_name:'2009',
            date_month:'20th September',
            year:'2009', 
            event_description_name:'Eucharistic Procession', 
            event_description:'On 20th September, 2009, Eucharistic Procession was conducted for the first time and this has revived the faith of the Parishioners manifold. The construction and the various fund raising schemes went hand in hand on a quick pace under the able and dynamic leadership of Fr. Jose Vazhakalayil.'
        },
        {
            id:'19',
            event_name:'2010',
            date_month:'21st November',
            year:'2010', 
            event_description_name:'Consecration of the Cathedral and the Pilgrim Centre', 
            event_description:'On 21st November, 2010 the dream and constant struggle of the Parishioners of Kalyan West ultimately bore fruit with the Consecration of the Cathedral and the Pilgrim Centre by His Excellency Mar Thomas Elavanal along with H.E. Mar Paul Chittalapilly, the first Bishop of Kalyan Diocese and Mar Jose Porunnedam, the Bishop of Mananthavady.'
        },
    ],

};

export default timeline;