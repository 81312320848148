import React, { Component } from 'react';
import { navigation } from '../data/navigation';
import SideNavMenu from './SideNavMenu';

class SideNavBar extends Component {
  render() {
    const menuItem = navigation.menuItems;
    return(
      <div className={ `ms-slidebar sb-slidebar sb-left sb-style-overlay ${ this.props.menuStatus }` } id="ms-slidebar">
        <div className="sb-slidebar-container">
          <header className="ms-slidebar-header">
            {/* <div className="ms-slidebar-login">
              <Link to="#" className="withripple">
                <i className="zmdi zmdi-account"></i> Login</Link>
              <Link to="#" className="withripple">
                <i className="zmdi zmdi-account-add"></i> Register</Link>
            </div> */}
            <div className="ms-slidebar-title">
              <form className="search-form">
                <input id="search-box-slidebar" type="text" className="search-input" placeholder="Search..." name="q" />
                <label htmlFor="search-box-slidebar">
                  <i className="zmdi zmdi-search"></i>
                </label>
              </form>
              <div className="ms-slidebar-t">
                {/* <span className="ms-logo ms-logo-sm">M</span> */}
                <h3>
                  <span> St. Thomas </span>
                  <div className="ml-1 d-inline">Cathedral</div>
                </h3>
              </div>
            </div>
          </header>
          <SideNavMenu menuItem={ menuItem } />
          {/* <div className="ms-slidebar-social ms-slidebar-block">
            <h4 className="ms-slidebar-block-title">Social Links</h4>
            <div className="ms-slidebar-social">
              <a href="javascript:void(0)" className="btn-circle btn-circle-raised btn-facebook">
                <i className="zmdi zmdi-facebook"></i>
                <span className="badge-pill badge-pill-pink">12</span>
                <div className="ripple-container"></div>
              </a>
              <a href="javascript:void(0)" className="btn-circle btn-circle-raised btn-twitter">
                <i className="zmdi zmdi-twitter"></i>
                <span className="badge-pill badge-pill-pink">4</span>
                <div className="ripple-container"></div>
              </a>
              <a href="javascript:void(0)" className="btn-circle btn-circle-raised btn-google">
                <i className="zmdi zmdi-google"></i>
                <div className="ripple-container"></div>
              </a>
              <a href="javascript:void(0)" className="btn-circle btn-circle-raised btn-instagram">
                <i className="zmdi zmdi-instagram"></i>
                <div className="ripple-container"></div>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default SideNavBar;