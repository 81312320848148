import { AssetHelper } from '../components';
import API from '../apiRoutes';
import _ from 'lodash';
const { BASE_URI, ROUTES } = API;
const { imagePath, getContentFrom } = AssetHelper;

const homeTiles = {
    cardsArr: [
        {
            cardTitle: 'Welcome to St. Thomas Cathedral Church',
            cardWidth: 8,
            cardType: 'html',
            cardHTML: 'We extend to you a warm invitation to join our parish community, together in this journey of faith in Jesus Christ.We have many associations within the parish that help us in our spiritual growth, you are welcome to join any group that interests you.Check on the rest of our website to see what our parish has to offer.'
        },
        {
            cardTitle: 'Announcements',
            cardWidth: 4,
            cardButton: true,
            cardType: 'list',
            buttonText: 'Read More',
            buttonLink: '/parish_life/announcements',
            dynamicContent: {
                apiURL: `${ BASE_URI }${ ROUTES.ANNOUNCEMENTS.GET }`,
            }
        },
        {
            cardTitle: 'Pope\'s Message',
            cardButton: true,
            cardWidth: 4,
            buttonText: 'Read More',
            buttonLink: '/message/pope',
            cardClass: 'text-white',
            cardImageObj: {
                imageSrc: imagePath('pope_francis.jpg'),
                altText: 'Image of Pope Francis',
            }
        },
        {
            cardTitle: 'Bishop\'s Message',
            cardButton: true,
            cardWidth: 4,
            buttonText: 'Read More',
            buttonLink: '/message/bishop',
            cardImageObj: {
                imageSrc: imagePath('bishop_thomas.jpg'),
                altText: 'Image of Bishop Thomas',
            }
        },
        {
            cardTitle: 'Parish Priest\'s Message',
            cardButton: true,
            cardWidth: 4,
            buttonText: 'Read More',
            buttonLink: '/message/vicar',
            cardImageObj: {
                imageSrc: imagePath('parish_priest.jpg'),
                altText: 'Image of Parish Priest',
            }
        },
        {
            cardTitle: 'Mass Timings',
            cardWidth: 4,
            cardType: 'html',
            cardButton: true,
            buttonText: 'Know More',
            buttonLink: '/about/timings',
            cardHTML: '<p class="font-weight-bold">Monday - Friday: <br /><span class="ml-30 font-weight-normal">6:30 am (Malayalam)</span> <br /><span class="ml-30 font-weight-normal">6:30 pm (Malayalam)</span> </p><p class="font-weight-bold">Saturday: <br /><span class="ml-30 font-weight-normal">6:30 am (Malayalam)</span> <br /><span class="ml-30 font-weight-normal">7:00 pm (Malayalam)</span> </p><p class="font-weight-bold">Sunday: <br /><span class="ml-30 font-weight-normal">7:30 am (Malayalam)</span> <br /><span class="ml-30 font-weight-normal">10:30 am (Marathi)</span> <br /><span class="ml-30 font-weight-normal">6:00 pm (Malayalam)</span> </p><p class="font-weight-bold">Monthly Retreat: Every Fourth Saturday: <br /><span class="ml-30 font-weight-normal">6 pm to 9 pm (Malayalam)</span></p>'
        },
        {
            cardTitle: 'Chapel: Public Adoration',
            cardWidth: 4,
            cardType: 'html',
            cardImageObj: {
                imageSrc: imagePath('adoration.jpg'),
                altText: 'Image of Holy Eucharist',
                width: 150,
                height: 200,
                style: {
                    margin: 'auto'
                }
            },
            cardHTML: '<p class="font-weight-bold">Every Day: <br /><span class="ml-30 font-weight-normal">6 am to 8 pm</span></p>'
        },
        // {
        //     cardTitle: 'Monthly Retreat',
        //     cardWidth: 4,
        //     cardType: 'html',
        //     cardHTML: '<p class="font-weight-bold">Every Fourth Saturday: <br /><span class="ml-30 font-weight-normal">6 pm to 9 pm (Malayalam)</span></p>'
        // },
        // {
        //     cardTitle: 'Obituaries',
        //     cardWidth: 4,
        //     buttonText: 'Open',
        //     buttonLink: '/parish_life/obituaries',
        //     cardType: 'carousel',
        //     cardButton: true,
        //     dynamicContent: {
        //         apiURL: `${ BASE_URI }${ ROUTES.OBITUARIES.GET }`,
        //     }
        // },
        // {
        //     cardTitle: 'Marriage Bans',
        //     cardWidth: 4,
        //     buttonText: 'Open',
        //     buttonLink: '/parish_life/marriage_banns',
        //     cardType: 'carousel',
        //     cardButton: true,
        //     dynamicContent: {
        //         apiURL: `${ BASE_URI }${ ROUTES.MARRIAGE_BANNS.GET }`,
        //     }
        // }
    ]
};

export const getHomeTilesData = () => {
    return new Promise((resolve) => {
        const { cardsArr } = homeTiles;
        const promiseArr = [];
        _.forEach(cardsArr, (value, key) => {
            if (value.dynamicContent) {
                const promise = getContentFrom(value.dynamicContent.apiURL)
                    .then((data) => {
                        value.cardData = data;
                    });
                promiseArr.push(promise);
            }
        });
        const timeoutID = setTimeout(() => resolve(homeTiles), 1500);
        Promise.all(promiseArr)
        .then(() => {
            clearTimeout(timeoutID);
            resolve(homeTiles);
        })
        .catch(() => console.log('Waiting for links to resolve!'));
    });

};