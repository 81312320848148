import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer, NavBar } from '../components';

const Home = () => {
  document.title = document.title + ' :: 404';
  return (
    <div className="500">
      <Header />
      <NavBar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-md-offset-2">
            <div className="card animated fadeInUp animation-delay-7 color-primary withripple">
              <div className="card-body-big color-dark">
                <div className="text-center">
                  <h1 className="color-primary">Error 500</h1>
                  <h2>Internal Server Error</h2>
                  <p className="lead lead-sm">Something has gone wrong we are trying to fix it.
                    <br />Meanwhile you can go back to the homepage.</p>
                  <Link to="/" className="btn btn-primary btn-raised">
                    <i className="zmdi zmdi-home" /> Go Home
                  </Link>
                </div>
              </div>
              <div className="ripple-container" /></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
